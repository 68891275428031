import { Box } from '@mui/material';
import { addVenueToInquiryDraft, removeVenueFromInquiry } from 'api';
import ConfirmationModal from 'components/ConfirmationModal';
import { EventHeaderIconsSlot } from 'components/EventHeader';
import HowToPopper from 'components/HowToPopper/HowToPopper';
import { SpinnerOverlay } from 'components/Spinner';
import { REJECTED_OR_ADDED, REJECTED_STATUSES } from 'components/VenueCard';
import VenueInquiry from 'components/VenueInquiry';
import { PageHeadline } from 'components/ui/Headline';
import keyBy from 'lodash/keyBy';
import { useSnackbar } from 'notistack';
import React, { useMemo, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { IntercomAPI } from 'react-intercom';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import { LoadCurrentInquiry, currentInquiryActions, useCurrentInquiry } from 'stores/current-inquiry';
import styled from 'styled-components';
import { i18n } from 'translation';
import { HEADER_HEIGHT } from 'utils/header';
import { Column, Copy, ExternalLink, Row, Spacer } from '../ui';
import BuildInquiry, { PageDescription } from './BuildInquiry';
import Contracting from './Contracting';
import Proposal from './Proposal';
import VenueListingV2 from './VenueListingV2';
import VenueSearch from './VenueSearch';
import { EventFormValues } from './Venues/Venues';

const TitleWrapper = styled('div')`
    padding: 32px 32px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

const PaddedContent = styled(Column)<{ sideMargin: number }>`
    height: 100%;
    padding: 0 ${({ sideMargin }) => sideMargin}px;
    box-sizing: border-box;
    scroll-margin: ${HEADER_HEIGHT}px;
`;

const FullWidthColumn = styled(Column)`
    height: 100%;
    width: 100%;
`;

const StyledLink = styled(Link)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};
`;

const MAX_INQUIRY_VENUE_SLOTS = 4;
const getActiveVenues = (venues: Bizly.Venue[]) => venues.filter(venue => !REJECTED_OR_ADDED.has(venue.status));
const getActiveVenuesCount = (venues: Bizly.Venue[]) => getActiveVenues(venues).length;

const getSelectedVenueInqs = (venues: Bizly.Venue[]) => venues.filter(venue => !REJECTED_STATUSES.has(venue.status));

const Venue = ({
    event,
    viewVenueListing,
    viewProposal,
    sideMargin = 32,
    pageOffset = 0,
}: {
    event: Bizly.Event;
    viewVenueListing: (venueId: number) => void;
    viewProposal: (venue?: Bizly.Venue) => void;
    suggestedVenues: Bizly.Venue[];
    venuesRefreshKey?: number;
    onUpdateVenues?: () => void;
    sideMargin?: number;
    pageOffset?: number;
}) => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const [submitKey] = React.useState(0);
    const [venueBooked, setVenueBooked] = React.useState(false);

    const setBookedVenue = (booked: boolean) => {
        setVenueBooked(booked);
        currentInquiryActions.load(event.id);
    };

    const methods = useForm<EventFormValues>();

    const {
        inquiry: currentInquiry,
        venues: currentInquiryVenues,
        loading: loadingCurrent,
        loaded: loadedCurrent,
    } = useCurrentInquiry();
    const inquiryId = currentInquiry && currentInquiry.id;

    const canSubmitInquiry = !venueBooked && event.editable && loadedCurrent && !event.cancelledAt;

    const isDraft = !loadingCurrent && currentInquiry?.draft;
    const isSubmitted = !loadingCurrent && currentInquiry?.submittedAt;

    React.useEffect(() => {
        if (currentInquiry && currentInquiryVenues) {
            const bookedVenue = !!currentInquiryVenues.find(venue => venue.booked);
            if (bookedVenue) setVenueBooked(bookedVenue);
        }
    }, [currentInquiry, currentInquiryVenues]);

    const availableSlots = React.useMemo(
        () =>
            !loadedCurrent || !currentInquiryVenues || venueBooked
                ? 0
                : MAX_INQUIRY_VENUE_SLOTS - getActiveVenuesCount(currentInquiryVenues),
        [loadedCurrent, venueBooked, currentInquiryVenues]
    );

    const addedVenuesCount = React.useMemo(
        () =>
            !currentInquiryVenues
                ? 0
                : currentInquiryVenues.filter(venueInquiry => venueInquiry.status === 'Added').length,
        [currentInquiryVenues]
    );

    const openSlots = React.useMemo(
        () => Math.max(availableSlots - addedVenuesCount, 0),
        [availableSlots, addedVenuesCount]
    );

    const selectedVenues = useMemo(() => {
        if (venueBooked) return {};
        return keyBy(
            getSelectedVenueInqs(currentInquiryVenues || []).map(venueInq => venueInq.venue),
            'id'
        );
    }, [venueBooked, currentInquiryVenues]);

    const [stagedVenue, setStagedVenue] = React.useState<Bizly.Venue | undefined>();
    const [updatingVenues, setUpdatingVenues] = React.useState(false);

    const contentRef = useRef<HTMLDivElement>(null);

    const addVenueConfirm = (venue: Bizly.Venue) => setStagedVenue(venue);

    async function addVenue(venue: Bizly.Venue) {
        if (openSlots > 0 && !selectedVenues[venue.id]) {
            setUpdatingVenues(true);

            try {
                const { inquiry } = await addVenueToInquiryDraft(event.id, {
                    inquiryId: inquiryId ?? undefined,
                    venueId: venue.id,
                });

                if (isSubmitted) {
                    // TODO: Revisit this restructuring of venue objects
                    currentInquiryActions.mergeVenues(event.id, [{ ...venue, venue: venue, status: 'Submitted' }]);
                    navigate(`/event/${event.id}/venue/inquiries`);
                } else {
                    currentInquiryActions.setInquiry(event.id, inquiry);
                    currentInquiryActions.mergeVenues(event.id, [{ ...venue, venue: venue, status: 'Added' }]);
                }
            } catch {
                enqueueSnackbar(i18n.error.default, { variant: 'error' });
            } finally {
                setUpdatingVenues(false);
            }
        }
        setStagedVenue(undefined);
    }

    async function removeVenue(venue: Bizly.Venue) {
        if (!inquiryId) return;

        setUpdatingVenues(true);

        try {
            const { inquiry } = await removeVenueFromInquiry(inquiryId, venue.id);

            currentInquiryActions.setInquiry(event.id, inquiry);
            currentInquiryActions.removeVenueInq(event.id, venue.id);
        } catch {
            enqueueSnackbar(i18n.error.default, { variant: 'error' });
        } finally {
            setUpdatingVenues(false);
        }
    }

    const addVenueIfCan = (venue: Bizly.Venue) =>
        openSlots > 0 && canSubmitInquiry && !loadingCurrent && !updatingVenues
            ? isSubmitted || venue.tempClosed
                ? addVenueConfirm(venue)
                : addVenue(venue)
            : undefined;

    const removeVenueIfCan =
        Object.keys(selectedVenues).length > 0 && canSubmitInquiry && !loadingCurrent && !updatingVenues && isDraft
            ? removeVenue
            : undefined;

    const modalContent = () => {
        if (stagedVenue?.tempClosed) {
            return {
                prompt: (
                    <>
                        {/* TODO: Needs support for JSX translation */}
                        <Copy>
                            {i18n.venue.closeCopy + ' '}
                            <ExternalLink onClick={() => IntercomAPI('show')}>{i18n.venue.contactSupport}</ExternalLink>
                            .
                        </Copy>
                        {updatingVenues && <SpinnerOverlay />}
                    </>
                ),
            };
        }

        return {
            prompt: (
                <>
                    <span>
                        {i18n.venue.inquirySubmissionHelper}
                        <Spacer small />
                        {i18n.venue.inquirySubmissionConfirm}
                    </span>
                    {updatingVenues && <SpinnerOverlay />}
                </>
            ),
            onProceed: () => addVenue(stagedVenue!),
        };
    };

    return (
        <FullWidthColumn>
            <LoadCurrentInquiry />

            {stagedVenue && (
                <ConfirmationModal
                    isActive
                    headline={i18n.venue.addToInquiry}
                    ctaLabel={i18n.button.add}
                    warning
                    onDismiss={() => setStagedVenue(undefined)}
                    {...modalContent()}
                />
            )}

            {/* Title */}
            <TitleWrapper>
                <Box>
                    <Routes>
                        <Route
                            path={'inquiries'}
                            element={
                                <>
                                    <Row alignItems="center">
                                        <PageHeadline noMarginBlock withDescription>
                                            {i18n.meetingsPage.sideNav.proposals}
                                        </PageHeadline>
                                        <HowToPopper sectionId="proposals" />
                                    </Row>
                                    <Spacer medium />
                                </>
                            }
                        />
                        {['inquiries/*', 'listing/*', 'proposal/*', 'contracting/*'].map(route => (
                            <Route path={route} key={route} element={null} />
                        ))}
                        <Route
                            path={'inquiries/:inquiryId'}
                            element={
                                <>
                                    <Row alignItems="center">
                                        <PageHeadline noMarginBlock withDescription>
                                            {i18n.venue.inquiry.buildYourInquiry}
                                        </PageHeadline>
                                        <HowToPopper sectionId="inquiry" />
                                    </Row>
                                    <Spacer small />
                                    <PageDescription large>
                                        Enter your requirements for your event below. Add your{' '}
                                        <StyledLink to={`/event/${event.id}/venue`}>venues</StyledLink> before
                                        submitting inquiry.
                                    </PageDescription>
                                    <Spacer large />
                                </>
                            }
                        />
                    </Routes>
                </Box>
                <Box>
                    <EventHeaderIconsSlot />
                </Box>
            </TitleWrapper>

            <PaddedContent sideMargin={sideMargin} ref={contentRef}>
                <Routes>
                    <Route
                        path={'listing/:venueId'}
                        element={
                            <FormProvider {...methods}>
                                <VenueListingV2
                                    addToInquiry={addVenueIfCan}
                                    removeFromInquiry={removeVenueIfCan}
                                    selectedVenues={selectedVenues}
                                    pageOffset={pageOffset}
                                />
                            </FormProvider>
                        }
                    />
                    <Route
                        path={'search'}
                        element={
                            <VenueSearch
                                event={event}
                                onSelect={addVenueIfCan}
                                onDeselect={removeVenueIfCan}
                                viewVenueListing={viewVenueListing}
                                selectedVenues={selectedVenues}
                                hideActions={false}
                            />
                        }
                    />
                    <Route path={'inquiries/:inquiryId'} element={<BuildInquiry />} />
                    <Route
                        path={'inquiries'}
                        element={
                            <VenueInquiry
                                viewVenueListing={viewVenueListing}
                                viewProposal={viewProposal}
                                submitKey={submitKey}
                                setVenueBooked={setBookedVenue}
                                readonly={!event.editable || !!event.cancelledAt}
                            />
                        }
                    />

                    <Route
                        path={'proposal/:proposalId/*'}
                        element={
                            <Proposal
                                venueBooked={venueBooked}
                                setVenueBooked={setBookedVenue}
                                viewVenueListing={viewVenueListing}
                            />
                        }
                    />
                    <Route path={'contracting/*'} element={<Contracting viewVenueListing={viewVenueListing} />} />
                    <Route
                        path="*"
                        element={
                            <VenueSearch<false>
                                event={event}
                                onSelect={addVenueIfCan}
                                onDeselect={removeVenueIfCan}
                                viewVenueListing={viewVenueListing}
                                selectedVenues={selectedVenues}
                                hideActions={false}
                            />
                        }
                    />
                </Routes>
            </PaddedContent>
        </FullWidthColumn>
    );
};

export default Venue;
