import { TSpacePOST } from 'api';
import Form from 'components/Form';
import { Pane } from 'components/Pane';
import { H5Headline } from 'components/ui/Headline';
import fontFns from 'fontFns';
import useThemedColor from 'hooks/useThemedColor';
import SpaceIconSVG from 'images/icons/space.svg?react';
import { cloneDeep } from 'lodash';
import keyBy from 'lodash/keyBy';
import React from 'react';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Column, Spacer } from 'ui';
import { tzMoment } from 'utils/moment';
import { TFormSectionProps, useRegisterValidator } from '../utils';
import { CommissionableField, TESCommissions } from './eventSpacesFBField';
import {
    AVFields,
    AVSchema,
    FBFields,
    FBSchema,
    costsFields,
    costsSchema,
    setupFields,
    setupSchema,
    timeAndSpaceFields,
    timeAndSpaceSchema,
} from './eventSpacesFormSchema';
import {
    TESFormBooking,
    TEventSpacesFormValue,
    TFBOption,
    TOption,
    TOptionsDictionary,
    TSpace,
    formDataToProposalForm,
    getErrorMessage,
    proposalFormToFormData,
    replaceObjInArray,
    sanitizedSpaces,
    selectedASpace,
    setFeeTaxOnAllES,
} from './utils';

const BorderedContent = styled(Column)`
    padding: 1rem 3rem;
    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};
`;

const Copy = styled.div`
    font-size: 18px;
    line-height: 1.5;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkerGrey)};
    white-space: pre-line;
`;

const paneStyles = (background: Themed.Color, borderColor: Themed.Color) => ({
    background,
    border: `1px solid ${borderColor}`,
    borderTop: '0px',
    padding: 0,
    overflow: 'hidden',
});

const SpaceFormContent = styled(Column)<{ header?: boolean }>`
    padding: 2rem 3rem 3rem 3rem;
    ${({ header, theme: { getColor, EColors } }) =>
        header ? `background: ${getColor(EColors.softAccentedBackground)};` : 'padding: 3rem 3rem 2rem 3rem;'}

    position: relative;
`;

const SingleBorder = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.softBorder)};
`;

const FormSectionHeading = styled(Copy)`
    font-size: 20px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.formHeading)};
    ${fontFns.formHeading}
`;

const SpaceIcon = styled(SpaceIconSVG)`
    position: absolute;
    left: 8px;
`;

const PricingPane = styled.div`
    background: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    padding: 1rem;
`;

type TBaseProps = {
    spaceUpdater: (
        dayIndex: number,
        spaceIndex: number,
        { field, value }: { field: string; value: TESFormBooking }
    ) => void;
    spaces: TSpace[];
    onUpdateVenueSpaces: (space: TSpacePOST) => Promise<TSpace[] | false>;
    AVOptions: TOptionsDictionary<TOption>;
    FBOptions: TOptionsDictionary<TFBOption>;
    DSOptions: TOptionsDictionary<TOption>;
    setupOptions: TOption[];
    currencyOptions: { id: string }[];
    disabled?: boolean;
};

const SpaceForm = ({
    space,
    spaceIndex,
    dayIndex,
    spaceUpdater,
    onUpdateVenueSpaces,
    spaces,
    AVOptions,
    FBOptions,
    DSOptions,
    setupOptions,
    currencyOptions,
    disabled,
}: {
    space: TESFormBooking;
    dayIndex: number;
    spaceIndex: number;
} & TBaseProps) => {
    const onChange = React.useCallback(
        (update: { field: string; value: TESFormBooking }) => {
            spaceUpdater(dayIndex, spaceIndex, update);
        },
        // we should not regenerate this function unless these change,
        // moving it into baseFormProps would regenerate this when space changes which happens all the time
        [dayIndex, spaceIndex, spaceUpdater]
    );

    const baseFormValueProps = React.useMemo(
        () => ({
            value: space,
            onChange,
            disabled,
        }),
        [space, onChange, disabled]
    );

    const requestedAv = React.useMemo(() => space.requestedAvIds || [], [space.requestedAvIds]);
    const requestedFb = React.useMemo(() => space.requestedFb || [], [space.requestedFb]);

    const venueSpaceSelected = React.useMemo(
        () => selectedASpace(space.proposedVenueSpaceId),
        [space.proposedVenueSpaceId]
    );
    const onlyShowRequest = !venueSpaceSelected;

    const timeSpaceFields = React.useMemo(
        () => timeAndSpaceFields(onlyShowRequest, spaces, onUpdateVenueSpaces),
        [onlyShowRequest, spaces, onUpdateVenueSpaces]
    );
    const mTimeSpaceSchema = React.useMemo(
        () => timeAndSpaceSchema(space.requestedSpaceName || i18n.proposalForm.eventSpaces.spaceIndex(spaceIndex + 1)),
        [space.requestedSpaceName, spaceIndex]
    );
    const mSetupFields = React.useMemo(() => setupFields(setupOptions), [setupOptions]);
    const mSetupSchema = React.useMemo(() => setupSchema(onlyShowRequest), [onlyShowRequest]);

    const mAVFields = React.useMemo(
        () => AVFields(requestedAv, AVOptions, onlyShowRequest),
        [requestedAv, onlyShowRequest, AVOptions]
    );
    const mAVSchema = AVSchema;
    const mFBFields = React.useMemo(
        () => FBFields(requestedFb, FBOptions, DSOptions, onlyShowRequest),
        [requestedFb, onlyShowRequest, FBOptions, DSOptions]
    );
    const mFBSchema = FBSchema;

    const currencyCode = space.currencyCode;
    const useDdr = space.useDdr === 'true' || false; // useDdr=true: daily room rate pricing, useDdr=false: per person rate

    return (
        <Column>
            <SpaceFormContent>
                <FormSectionHeading>
                    <SpaceIcon />
                    {i18n.proposalForm.eventSpaces.clientSpaceRequest}
                </FormSectionHeading>
                <Spacer larger />

                <Form fields={timeSpaceFields} schema={mTimeSpaceSchema} {...baseFormValueProps} />
            </SpaceFormContent>
            <SingleBorder />

            <SpaceFormContent header>
                <FormSectionHeading>{i18n.proposalForm.eventSpaces.setupRequests}</FormSectionHeading>
                <Spacer xsmall />
                <Copy>
                    {i18n.proposalForm.eventSpaces.setupRequestsDescription}
                    {onlyShowRequest && ` ${i18n.proposalForm.eventSpaces.selectSpace}`}
                </Copy>
                <Spacer larger />

                <Form fields={mSetupFields} schema={mSetupSchema} {...baseFormValueProps} />
                {requestedAv.length > 0 && (
                    <>
                        <Spacer largest />
                        <Form fields={mAVFields} schema={mAVSchema} {...baseFormValueProps} />
                    </>
                )}
                {requestedFb.length > 0 && (
                    <>
                        <Spacer largest />
                        <Form fields={mFBFields} schema={mFBSchema} {...baseFormValueProps} />
                    </>
                )}
                {venueSpaceSelected && (
                    <>
                        <Spacer largest />
                        <PricingPane>
                            <Form
                                fields={costsFields(currencyOptions, currencyCode)}
                                schema={costsSchema(useDdr)}
                                {...baseFormValueProps}
                            />
                        </PricingPane>
                    </>
                )}
            </SpaceFormContent>
        </Column>
    );
};

const EventSpacesDayForm = ({
    index,
    data,
    onCopySpaceDetailsWithinDay,
    onCopySpaceDetailsToAll,
    ...childProps
}: {
    index: number;
    data: TESFormBooking[];
    onCopySpaceDetailsWithinDay: (spaceIndex: number) => void;
    onCopySpaceDetailsToAll: (dayIndex: number, spaceIndex: number) => void;
} & TBaseProps) => {
    const { pureWhite, lightGrey } = useThemedColor();

    return (
        <Pane
            key={data[0].requestedDate}
            label={i18n.common.dayIndex(index + 1) + ` - ` + i18n.proposalForm.eventSpaces.spaceCount(data.length)}
            secondaryLabel={tzMoment(data[0].requestedDate + 'T00:00:00').format('dddd, ll')}
            stackedLabels
            beginExpanded
            invert
            stickyHeader
            contentStyle={paneStyles(pureWhite, lightGrey)}
        >
            {() => (
                <Column>
                    {data.map((space, spaceIndex) => (
                        <Column key={space.requestedDate + space.requestedStartTime}>
                            <SpaceForm dayIndex={index} spaceIndex={spaceIndex} space={space} {...childProps} />
                            {/* {spaceIndex < data.length - 1 && (
                                <>
                                    <Spacer />
                                    <Row itemSpacing="largest" justifyContent="center">
                                        <Button onClick={() => onCopySpaceDetailsWithinDay(spaceIndex)}>
                                            {i18n.proposalForm.eventSpaces.copySpaceBelow}
                                        </Button>
                                        <Spacer />
                                        <Button onClick={() => onCopySpaceDetailsToAll(index, spaceIndex)}>
                                            {i18n.proposalForm.eventSpaces.copySpaceToAll}
                                        </Button>
                                    </Row>
                                    <Spacer />
                                    <SingleBorder />
                                </>
                            )} */}
                        </Column>
                    ))}
                </Column>
            )}
        </Pane>
    );
};

export default function EventSpacesForm({
    onChange,
    options,
    onUpdateVenueSpaces,
    registerValidator,
    disabled,
    ...rest
}: TFormSectionProps) {
    const {
        spaceSetups = [],
        avOptions = [],
        fbOptions = [],
        diningStyles = [],
        venueSpaces = [],
        currencies = [],
    } = options || {};
    const spaces = React.useMemo(() => sanitizedSpaces(venueSpaces), [venueSpaces]);
    const AVOptionsDict = React.useMemo(() => keyBy(avOptions, option => option.id), [avOptions]);
    const FBOptionsDict = React.useMemo(() => keyBy(fbOptions, option => option.id), [fbOptions]);
    const DSOptionsDict = React.useMemo(() => keyBy(diningStyles, option => option.id), [diningStyles]);

    const [data, setData] = React.useState<TEventSpacesFormValue>(proposalFormToFormData(rest));

    useRegisterValidator(data, registerValidator, getErrorMessage, formDataToProposalForm);

    const spaceUpdater = React.useMemo(
        () =>
            (
                dayIndex: number,
                spaceIndex: number,
                { field, value: newSpaceData }: { field: string; value: TESFormBooking }
            ) => {
                setData(prevData => {
                    const prevDayData = prevData.eventSpacesByDay[dayIndex];

                    let newEventSpaces = replaceObjInArray(
                        prevData.eventSpacesByDay,
                        dayIndex,
                        replaceObjInArray(prevDayData, spaceIndex, newSpaceData)
                    );

                    newEventSpaces = (() => {
                        const { gratuity, salesTax, serviceCharge, currencyCode, useDdr } = newSpaceData;

                        switch (field) {
                            case 'gratuity':
                                return setFeeTaxOnAllES(newEventSpaces, { gratuity });
                            case 'salesTax':
                                return setFeeTaxOnAllES(newEventSpaces, { salesTax });
                            case 'serviceCharge':
                                return setFeeTaxOnAllES(newEventSpaces, { serviceCharge });
                            case 'currencyCode':
                                return setFeeTaxOnAllES(newEventSpaces, { currencyCode });
                            case 'useDdr':
                                return setFeeTaxOnAllES(newEventSpaces, { useDdr });
                            default:
                                return newEventSpaces;
                        }
                    })();

                    onChange();
                    return {
                        ...prevData,
                        eventSpacesByDay: newEventSpaces,
                    };
                });
            },
        [setData, onChange]
    );

    const venueSpaceSelected = React.useMemo(
        () => data.eventSpacesByDay.flat().filter(space => selectedASpace(space.proposedVenueSpaceId)).length > 0,
        [data]
    );

    const onCommissionChange = ({ value, field }: { value: TESCommissions; field: keyof TESCommissions }) => {
        setData({
            ...data,
            ...value,
            [field]: value[field] === null ? null : value[field] ?? 0,
        });
        onChange();
    };

    const onCopySpaceDetailsWithinDay = (dayIndex: number, spaceIndex: number) => {
        setData(prevData => {
            const currentDaySpaces = [...prevData.eventSpacesByDay[dayIndex]];
            const nextSpaceIndex = spaceIndex + 1;
            if (nextSpaceIndex < currentDaySpaces.length) {
                currentDaySpaces[nextSpaceIndex] = cloneDeep(currentDaySpaces[spaceIndex]);
                return {
                    ...prevData,
                    eventSpacesByDay: replaceObjInArray(prevData.eventSpacesByDay, dayIndex, currentDaySpaces),
                };
            }

            return prevData;
        });

        onChange();
    };

    const onCopySpaceDetailsBetweenDays = (dayIndex: number) => {
        setData(prevData => {
            if (dayIndex < prevData.eventSpacesByDay.length - 1) {
                const newData = cloneDeep(prevData);
                newData.eventSpacesByDay[dayIndex + 1] = cloneDeep(newData.eventSpacesByDay[dayIndex]);
                return newData;
            }

            return prevData;
        });

        onChange();
    };

    const onCopySpaceDetailsToAll = (dayIndex: number, spaceIndex: number) => {
        setData(prevData => {
            const eventSpacesByDay = cloneDeep(prevData.eventSpacesByDay);
            const currentDaySpaces = cloneDeep(eventSpacesByDay[dayIndex]);

            for (let i = spaceIndex + 1; i < currentDaySpaces.length; i++) {
                eventSpacesByDay[dayIndex][i] = cloneDeep(currentDaySpaces[spaceIndex]);
            }

            for (let i = dayIndex + 1; i < eventSpacesByDay.length; i++) {
                eventSpacesByDay[i] = currentDaySpaces.map(() => cloneDeep(currentDaySpaces[spaceIndex]));
            }

            eventSpacesByDay.forEach((daySpaces, i) => {
                for (let j = 0; j < eventSpacesByDay[i].length; j++) {
                    const {
                        inquirySpaceId,
                        proposalSpaceId,
                        proposedDate,
                        proposedStartTime,
                        proposedEndTime,
                        requestedDate,
                        requestedStartTime,
                        requestedEndTime,
                        requestedGuests,
                        requestedSpaceName,
                    } = prevData.eventSpacesByDay[i][j];

                    Object.assign(daySpaces[j], {
                        inquirySpaceId,
                        proposalSpaceId,
                        proposedDate,
                        proposedStartTime,
                        proposedEndTime,
                        requestedDate,
                        requestedStartTime,
                        requestedEndTime,
                        requestedGuests,
                        requestedSpaceName,
                    });
                }
            });

            return {
                ...prevData,
                eventSpacesByDay,
            };
        });

        onChange();
    };

    return (
        <Column>
            <H5Headline>{i18n.proposalForm.eventSpaces.heading}</H5Headline>
            <Spacer small />
            <Copy>{i18n.proposalForm.eventSpaces.eventSpacesDescription}</Copy>

            {venueSpaceSelected && (
                <>
                    <Spacer largest />
                    <BorderedContent>
                        <CommissionableField
                            value={{
                                eventSpacesCommission:
                                    data.eventSpacesCommission === undefined ? null : data.eventSpacesCommission,
                                fbCommission: data.fbCommission === undefined ? null : data.fbCommission,
                            }}
                            onChange={onCommissionChange}
                            disabled={disabled}
                        />
                    </BorderedContent>
                </>
            )}

            <Spacer largest />

            {(rest.eventSpaces || []).length === 0 ? (
                <Copy>{i18n.proposalForm.eventSpaces.noMeetingSpaceRequest}</Copy>
            ) : null}

            <Column>
                {data.eventSpacesByDay.map((day, dayIndex) => (
                    <Column key={`space-${day[0].requestedDate}`}>
                        <EventSpacesDayForm
                            index={dayIndex}
                            data={day}
                            onCopySpaceDetailsWithinDay={spaceIndex =>
                                onCopySpaceDetailsWithinDay(dayIndex, spaceIndex)
                            }
                            onCopySpaceDetailsToAll={onCopySpaceDetailsToAll}
                            spaces={spaces}
                            spaceUpdater={spaceUpdater}
                            onUpdateVenueSpaces={onUpdateVenueSpaces}
                            AVOptions={AVOptionsDict}
                            FBOptions={FBOptionsDict}
                            DSOptions={DSOptionsDict}
                            setupOptions={spaceSetups}
                            currencyOptions={currencies.map(({ code }) => ({ id: code })) ?? []}
                            disabled={disabled}
                        />
                        <Spacer />
                        {/* {dayIndex === data.eventSpacesByDay.length - 1 ? null : (
                            <>
                                <Spacer />
                                <Row itemSpacing="largest" justifyContent="center">
                                    <Button onClick={() => onCopySpaceDetailsBetweenDays(dayIndex)}>
                                        {i18n.proposalForm.eventSpaces.copySpaceBelow}
                                    </Button>
                                    <Spacer />
                                    <Button onClick={() => onCopySpaceDetailsToAll(dayIndex, day.length - 1)}>
                                        {i18n.proposalForm.eventSpaces.copySpaceToAll}
                                    </Button>
                                </Row>
                                <Spacer />
                            </>
                        )} */}
                    </Column>
                ))}
            </Column>
        </Column>
    );
}
