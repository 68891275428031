import { HOTEL_CONF, INVITE, NOTE, SURVEY } from 'pages/EditParcel/utils';
export default {
    // PAGES
    signIn: {
        heading: `TODO`,
        subheading: `TODO`,
        welcome: `TODO`,
        policyPrefix: `TODO`,
        validEmailError: `TODO`,
        didntReceiveEmail: `TODO`,
        resendEmail: `TODO`,
        dontHaveAccount: `TODO`,
        signUpHere: `TODO`,
        loginHeading: `TODO`,
        loginSubheading: `TODO`,
        submitButtonLabel: `TODO`,
        invalidEmail: `TODO`,
        acceptInviteHeading: `TODO`,
        sendLink: `TODO`,
        agreement: `TODO`,
        tos: `TODO`,
        privacyPolicy: `TODO`,
        ssoLoginRequired: `TODO`,
        ssoLoginDescription: `TODO`,
        ssoLoginProceed: `TODO`,
        emailSent: `TODO`,
        emailSentDescription: `TODO`,
        backToSignIn: `TODO`,
        authenticating: `TODO`,
    },
    userMenu: {
        settings: `TODO`,
        myProfile: `TODO`,
        integrations: `TODO`,
        approvals: `TODO`,
        teamSettings: `TODO`,
        signOut: `TODO`,
    },
    teamSelector: {
        switchTeam: `TODO`,
        rejectedInvite: `TODO`,
    },
    userProfile: {
        enterInfo: (teamName: string) => `TODO`,
        setUpYourProfile: `TODO`,
        phoneNumberError: `TODO`,
        select: `TODO`,
        policyPrefix: `TODO`,
        completeYourProfile: (teamName: string) => `TODO`,
        errorSavingProfile: `TODO`,
    },
    approvals: {
        header: `TODO`,
        meetingName: `TODO`,
        meetingOwner: `TODO`,
        venueName: `TODO`,
        proposalTotal: `TODO`,
        proposalLink: `TODO`,
        status: `TODO`,
        actionDate: `TODO`,
        reason: `TODO`,
        newBudget: `TODO`,
        viewLink: `TODO`,
        showMore: `TODO`,
        showLess: `TODO`,
    },
    teamSettings: {
        approversEmail: `TODO`,
        header: `TODO`,
        profile: `TODO`,
        members: `TODO`,
        teamLogo: `TODO`,
        teamName: `TODO`,
        teamColor: `TODO`,
        homepageBannerMessage: `TODO`,
        internalReferenceLabel: `TODO`,
        internalReferenceFile: `TODO`,
        costCenterFieldLabel: `TODO`,
        costCenterFileField: `TODO`,
        redirectMessage: `TODO`,
        cancellationMessage: `TODO`,
        cancellationEmails: `TODO`,
        defaultInternalReference: `TODO`,
        defaultCostCenterReference: `TODO`,
        cancellationEmail: {
            emailName: `TODO`,
            buttonLabel: `TODO`,
        },
        contactTeamAdmin: `TODO`,
        removeCustomizationWarning: `TODO`,
        requireBudgetApproval: `TODO`,
        maximumBudgetLimit: `TODO`,
        maximumAccommodationRooms: `TODO`,
        limitDescription: `TODO`,
        limitPerNight: `TODO`,
        limitPerNightDescription: `TODO`,
        limitPerMeeting: `TODO`,
        limitPerMeetingDescription: `TODO`,
        membersTable: {
            active: `TODO`,
            invited: `TODO`,
            rejected: `TODO`,
            inviteByEmail: `TODO`,
            name: `TODO`,
            role: `TODO`,
            status: `TODO`,
            lastActive: `TODO`,
            teamAdmin: `TODO`,
            teamMember: `TODO`,
        },
        error: {
            teamNameRequired: `TODO`,
            teamLogoRequired: `TODO`,
            teamColorValid: `TODO`,
            invalidEmail: `TODO`,
            alreadyAMember: `TODO`,
            budgetApproverRequired: `TODO`,
            maximumBudgetRequired: `TODO`,
            invalidFile: `TODO`,
            roomLimitRequired: `TODO`,
        },
    },
    homepage: {
        welcome: (user: string) => `TODO`,
        welcomeToBizly: `TODO`,
        playbooksHeader: `TODO`,
        createNewMeeting: `TODO`,
        createMeeting: `TODO`,
        createPlaybook: `TODO`,
        viewPlaybooks: `TODO`,
        upcomingMeetings: `TODO`,
        viewAllMeetings: `TODO`,
        healthAndSafetyInfo: `TODO`,
        viewDetailsHere: `TODO`,
        activity: `TODO`,
        untitled: `TODO`,
        header: {
            eventConcierge: `TODO`,
        },
        sideNav: {
            meetings: `TODO`,
            search: `TODO`,
            events: `TODO`,
            templates: `TODO`,
            venues: `TODO`,
            reporting: `TODO`,
            help: `TODO`,
            hi: (user: string) => `TODO`,
            hiUser: `TODO`,
            creditScore: (credit: number) => `TODO`,
            creditDescription: `TODO`,
            sourceSiteTitle: `TODO`,
        },
        createMeetingModal: {
            header: (template: string) => `TODO`,
            headerNoTemplate: `TODO`,
            almostThere: `TODO`,
            copyLine1: `TODO`,
            copyLine2: `TODO`,
            completeRequiredFields: `TODO`,
            invalidDateRange: `TODO`,
            viewPlaybook: `TODO`,
            meetingName: `TODO`,
            location: `TODO`,
            budget: `TODO`,
            playbook: `TODO`,
            cventEvent: `TODO`,
            noPlaybook: `TODO`,
            none: `TODO`,
            virtualGatherings: `TODO`,
            teamProtocol: (teamName: string) => `TODO`,
            requiredFieldsError: `TODO`,
            wrongUserError: `TODO`,
            objetiveTitle: `TODO`,
            objectiveDescription: `TODO`,
            objectiveDetailDescription: `TODO`,
            eventName: `TODO`,
            eventDescription: `TODO`,
            bookTitle: `TODO`,
            selectVenueType: `TODO`,
            eventSpace: `TODO`,
            hotelRoomBlock: `TODO`,
            privateDining: `TODO`,
            groupActivity: `TODO`,
            conferenceRoom: `TODO`,
            office: `TODO`,
            dateTitle: `TODO`,
            youCanEditThisLater: `TODO`,
            singleDay: `TODO`,
            multiDay: `TODO`,
            guestsTitle: `TODO`,
            numberDescription: `TODO`,
            locationTitle: `TODO`,
            locationDescription: `TODO`,
            fixedLocation: `TODO`,
            recommendLocation: `TODO`,
            find: `TODO`,
            paymentTitle: `TODO`,
            paymentDescription: `TODO`,
            grandTotal: `TODO`,
            perPerson: `TODO`,
            additionalInfoTitle: `TODO`,
            additionalInfoDescription: `TODO`,
            eventInfoDescription: `TODO`,
            clickHere: `TODO`,
            sampleDescription: `TODO`,
        },
        actionItems: {
            title: `TODO`,
            reviewBudgetApprovalRequest: `TODO`,
            headCells: {
                meetingName: `TODO`,
                date: `TODO`,
                owner: `TODO`,
                approvalRequestDetails: `TODO`,
                proposalExpiryDate: `TODO`,
            },
        },
        actionItemsModal: {
            rejectedProposalRequest: `TODO`,
            approvedProposalRequest: `TODO`,
            notes: (eventName: string, requestNotes: string) => `TODO`,
            meetDetails: {
                meetingBudget: `TODO`,
                linkToMeeting: `TODO`,
            },
            proposalDetails: {
                title: `TODO`,
                venueName: `TODO`,
                roomHeldUntil: `TODO`,
                proposalTotal: `TODO`,
                meetingType: `TODO`,
                linkToProposal: `TODO`,
            },
            reasonForBudgetApproval: `TODO`,
            currentBudget: `TODO`,
            actionRequired: `TODO`,
            approve: `TODO`,
            reject: `TODO`,
            budgetValidation: `TODO`,
            totalAmountNote: `TODO`,
            rejectPrompt: `TODO`,
            rejectReasonLabel: `TODO`,
            reasonPlaceholder: `TODO`,
            rejectReasonValidation: `TODO`,
        },
        eventButtons: {
            newEvent: `TODO`,
            startFromTemplate: `TODO`,
            pricesToBeat: `TODO`,
            createAnEvent: `TODO`,
        },
        eventTableActions: {
            yourEvents: `TODO`,
            all: `TODO`,
            upcoming: `TODO`,
            createdByYou: `TODO`,
            actionItems: `TODO`,
            grid: `TODO`,
            list: `TODO`,
        },
        eventStateMessages: {
            default: {
                createdByOwner: {
                    title: `TODO`,
                    content: `TODO`,
                },
            },
            admin: {
                all: {
                    title: `TODO`,
                    content: `TODO`,
                },
                upcoming: {
                    title: `TODO`,
                    content: `TODO`,
                },
                actionItems: {
                    title: `TODO`,
                    content: `TODO`,
                },
            },
            user: {
                all: {
                    title: `TODO`,
                    content: `TODO`,
                },
                upcoming: {
                    title: `TODO`,
                    content: `TODO`,
                },
            },
        },
    },
    notifications: {
        title: `TODO`,
        newMessagesCopy: (newCount: number, total: number) => `TODO`,
        inquiryTurnedDown: `TODO`,
        uploadContract: `TODO`,
        proposalReceived: `TODO`,
        messageReceived: `TODO`,
        proposalCancelled: `TODO`,
        proposalUpdated: `TODO`,
        expirationDateUpdated: `TODO`,
        expirationDateRejected: `TODO`,
        uploadFinalSpend: `TODO`,
        viewProposal: `TODO`,
        filters: `TODO`,
        all: `TODO`,
        unread: `TODO`,
        read: `TODO`,
    },
    footer: {
        privacy: `TODO`,
        helpCenter: `TODO`,
        copyright: (year: number) => `TODO`,
    },
    meetingsPage: {
        eventDetails: `TODO`,
        meetings: `TODO`,
        scheduled: `TODO`,
        unscheduled: `TODO`,
        drafts: `TODO`,
        department: `TODO`,
        createNewMeeting: `TODO`,
        cancelledOn: (dateTime: string) => `TODO`,
        meetingRoom: `TODO`,
        sideNav: {
            overview: `TODO`,
            inquiryBuilder: `TODO`,
            venues: `TODO`,
            venuesShortList: `TODO`,
            proposals: `TODO`,
            contracting: `TODO`,
            guestlist: `TODO`,
            communication: `TODO`,
            virtual: `TODO`,
            search: `TODO`,
            inquiry: `TODO`,
            invites: `TODO`,
            expense: `TODO`,
            spendTracker: `TODO`,
        },
        noTemplate: `TODO`,
        meetingDetails: `TODO`,
        template: `TODO`,
        templateDescription: `TODO`,
        none: `TODO`,
        cventEvent: `TODO`,
        meetingName: `TODO`,
        startDate: `TODO`,
        startTime: `TODO`,
        endDate: `TODO`,
        endTime: `TODO`,
        dateDisabled: {
            leadingText: `TODO`,
        },
        eventDescription: `TODO`,
        descriptionNote: `TODO`,
        budget: `TODO`,
        totalBudget: `TODO`,
        perPersonBudget: `TODO`,
        costCenter: `TODO`,
        internalReference: `TODO`,
        meetingType: `TODO`,
        meetingFormat: `TODO`,
        virtual: `TODO`,
        // for virtual meeting
        inPerson: `TODO`,
        // for in-person meeting
        hybrid: `TODO`,
        // for hybrid meeting
        venueDescription: `TODO`,
    },
    search: {
        searchPlaceholder: `TODO`,
        meetings: `TODO`,
        scheduled: `TODO`,
        unscheduled: `TODO`,
        drafts: `TODO`,
        meetingList: {
            activity: `TODO`,
            cancelled: `TODO`,
        },
    },
    meetingDashboard: {
        eventEditTooltip: `TODO`,
        cancellationDetails: `TODO`,
        teamCancellationProtocol: (teamName: string) => `TODO`,
        deleteConfirmation: `TODO`,
        cancelWithNotes: `TODO`,
        cancelError: `TODO`,
        untitledMeeting: `TODO`,
        purpose: `TODO`,
        cancellationProtocolInfo: (teamName: string) => `TODO`,
        cancellationNotes: `TODO`,
        notesPlaceholder: `TODO`,
        meetingBriefing: `TODO`,
        meetingCompleted: `TODO`,
        uploadFinalSpendDescription: `TODO`,
        completeMeetingDescription: `TODO`,
        goToSpendTracker: `TODO`,
        goToContracting: `TODO`,
        headerSection: {
            settings: {
                meetingSettings: `TODO`,
                meetingType: `TODO`,
                costCenter: `TODO`,
                internalReference: `TODO`,
                budget: `TODO`,
                internal: `TODO`,
                external: `TODO`,
                department: `TODO`,
            },
            documents: {
                yourMeetingFiles: `TODO`,
                subheading: `TODO`,
                emptyFiles: `TODO`,
            },
            spendTracking: {
                spendTracking: `TODO`,
                budget: `TODO`,
                estimate: `TODO`,
                finalSpend: `TODO`,
                budgetSaved: `TODO`,
                estimatesSaved: `TODO`,
                invoicesSaved: `TODO`,
                finalSpendTracker: `TODO`,
                uploadFinalInvoice: `TODO`,
                maxFileUploadSize: `TODO`,
                finalSpendingNotAvailable: `TODO`,
                fileTooLarge: (filename: string) => `TODO`,
                spendEstimate: `TODO`,
                uploadContracts: `TODO`,
            },
            collaborators: {
                invite: `TODO`,
                addCollaborators: `TODO`,
                canEdit: `TODO`,
                canView: `TODO`,
                enterEmail: `TODO`,
                askAdmin: `TODO`,
                invitePrompt: `TODO`,
                suggestion: `TODO`,
                collaboratorsInvited: `TODO`,
                collaboratorsAdded: `TODO`,
                collaboratorCanEdit: `TODO`,
                collaboratorCanView: `TODO`,
                inviteError: `TODO`,
                addError: `TODO`,
                emailInviteError: `TODO`,
                emailAddError: `TODO`,
                changePermissionError: `TODO`,
                noCollaborators: `TODO`,
                collab: `TODO`,
            },
        },
        checklist: {
            myChecklist: `TODO`,
            defaultSubheading: `TODO`,
            startedSubheading: `TODO`,
            progressSubheading: `TODO`,
            finishedSubheading: `TODO`,
            onYourWay: `TODO`,
            greatStart: `TODO`,
            doingGreat: `TODO`,
            nice: `TODO`,
            onFire: `TODO`,
            nailIt: `TODO`,
            preMeeting: `TODO`,
            dayOf: `TODO`,
            postMeeting: `TODO`,
            taskCompleted: (completed: number, total: number) => `TODO`,
        },
        venueSection: {
            recommendedVenues: `TODO`,
            exploreMoreVenues: `TODO`,
            venues: `TODO`,
            venue: `TODO`,
            findVenue: `TODO`,
            defaultSubheading: `TODO`,
            noCitySubheading: `TODO`,
            hasInquiriesSubheading: `TODO`,
            hasBookedSubheading: (date: string) => `TODO`,
            unknownDates: `TODO`,
            cancelled: `TODO`,
        },
        roomBlockSection: {
            headline: `TODO`,
            description: `TODO`,
            formSectionHelper: `TODO`,
            on: `TODO`,
            off: `TODO`,
            roomTypeLabel: `TODO`,
            preferredRoomType: `TODO`,
            roomTypePlaceholder: `TODO`,
            single: `TODO`,
            double: `TODO`,
            suite: `TODO`,
            handicapAccessible: `TODO`,
            accessible: `TODO`,
            additionalNotes: `TODO`,
            notesPlaceholder: `TODO`,
            respondDeadlineLabel: `TODO`,
            postCutoffContact: `TODO`,
            exportCsv: `TODO`,
            importNote: `TODO`,
            inquiryBuilderPage: `TODO`,
            importFromRoomBlock: `TODO`,
        },
        vendorsSection: {
            virtualMeeting: `TODO`,
            defaultSubheading: `TODO`,
            hasVirtualMeetingSubheading: `TODO`,
            addVirtualMeeting: `TODO`,
        },
        guestListSection: {
            guestList: `TODO`,
            defaultSubheading: `TODO`,
            guestDetails: `TODO`,
            deleteGuest: `TODO`,
            revokeInvite: `TODO`,
            invalidRoomBlock: `TODO`,
        },
        communicationSection: {
            communication: `TODO`,
            defaultSubheading: `TODO`,
        },
    },
    playbooks: {
        apply: `TODO`,
        playbooks: `TODO`,
        subHeading: `TODO`,
        recommendationSubHeading: `TODO`,
        createNewPlaybook: `TODO`,
        createMeeting: `TODO`,
        personalTitle: `TODO`,
        sharedTitle: (teamName: string) => `TODO`,
        sharedTitleGeneric: `TODO`,
        globalTitle: `TODO`,
        personalSubheading: `TODO`,
        sharedSubheading: `TODO`,
        globalSubheading: `TODO`,
        filterByCategories: `TODO`,
        playbookForTag: (name: string) => `TODO`,
        playbookModalCreate: `TODO`,
        playbookModalCreatePrompt: `TODO`,
        playbookModalError: `TODO`,
        eventStateMessages: {
            all: {
                title: `TODO`,
                content: `TODO`,
            },
            teamTemplates: {
                title: `TODO`,
                content: `TODO`,
            },
            createdByOwner: {
                title: `TODO`,
                content: `TODO`,
            },
            recommendedTemplates: {
                title: `TODO`,
                content: `TODO`,
            },
        },
    },
    playbook: {
        publishToTeam: `TODO`,
        publishHeadline: `TODO`,
        publishPrompt: `TODO`,
        deletePlaybook: `TODO`,
        deletePrompt: `TODO`,
        view: `TODO`,
        createEvent: `TODO`,
        edit: `TODO`,
        delete: `TODO`,
    },
    venues: {
        title: `TODO`,
        subHeading: `TODO`,
        venue: `TODO`,
        venueSubtitle1: `TODO`,
        venueSubtitle2: `TODO`,
        venueSubtitle3: `TODO`,
        createMeeting: `TODO`,
    },
    playbookWizard: {
        steps: {
            basicDetails: `TODO`,
            search: `TODO`,
            inquiry: `TODO`,
            communication: `TODO`,
            collaboration: `TODO`,
            preview: `TODO`,
        },
        header: {
            saved: `TODO`,
            updatePlaybook: `TODO`,
        },
        basicInfo: {
            coverPhoto: `TODO`,
            namePrompt: `TODO`,
            description: `TODO`,
        },
        collaboration: {
            collaborators: `TODO`,
            addCollaborators: `TODO`,
            collaboratorsDescription: `TODO`,
            documents: `TODO`,
            resourcesDescription: `TODO`,
            collaboratorsInvited: `TODO`,
            collaboratorsAdded: `TODO`,
        },
        preview: {
            venueSearchFilters: `TODO`,
            inquiry: `TODO`,
            communications: `TODO`,
            collaboration: `TODO`,
        },
        nameRequiredError: `TODO`,
    },
    meetingSearch: {
        meetingName: `TODO`,
        status: `TODO`,
        date: `TODO`,
        owner: `TODO`,
        activity: `TODO`,
    },
    integrations: {
        connectAnIntegration: `TODO`,
    },
    proposals: {
        home: `TODO`,
        inquiries: `TODO`,
        bookings: `TODO`,
        listings: `TODO`,
        messages: `TODO`,
        calender: `TODO`,
        anayltics: `TODO`,
        settings: `TODO`,
    },
    proposalForm: {
        selectRejectReason: `TODO`,
        provideOtherRejectReason: `TODO`,
        viewContract: `TODO`,
        viewContract1: `TODO`,
        viewContract2: `TODO`,
        changeCurrency: `TODO`,
        changeCurrencyDescription: `TODO`,
        sections: {
            contactInformation: `TODO`,
            guestRooms: `TODO`,
            meetingSpaces: `TODO`,
            finishingTouches: `TODO`,
            proposalStatus: `TODO`,
        },
        banner: {
            extensionRequestCopy: `TODO`,
            editExpirationDate: `TODO`,
            rejectRequest: `TODO`,
        },
        header: {
            createProposal: `TODO`,
            greetingsCopy: (name: string, companyName: string) => `TODO`,
            clientDetails: `TODO`,
            clientAgreement: `TODO`,
            meetingDetails: `TODO`,
            cancelled: `TODO`,
            datesAreFlexible: `TODO`,
            clientNotes: `TODO`,
            alternativeDates: `TODO`,
            alternativeDatesPlaceholder: `TODO`,
            commissionableEvent: `TODO`,
            bizlyIATANumber: `TODO`,
            commissionPayout: `TODO`,
            contract: `TODO`,
        },
        status: {
            inquiryAlreadyRejected: `TODO`,
            inquiryRejected: `TODO`,
            proposalAlreadyCancelled: `TODO`,
            proposalCancelled: `TODO`,
            inquiryInvalid: `TODO`,
            inquiryCancelled: `TODO`,
            proposalSubmitted: `TODO`,
            proposalSubmittedWithUserChange: `TODO`,
            deadlineCopy: (clientName: string, deadline: string) => `TODO`,
            proposalAccepted: (clientName: string) => `TODO`,
            proposalAcceptedCopy: `TODO`,
            proposalRejected: `TODO`,
        },
        button: {
            withdrawProposal: `TODO`,
            createProposal: `TODO`,
            editProposal: `TODO`,
            viewProposal: `TODO`,
            turnDownInquiry: `TODO`,
        },
        modal: {
            rejectInquiryTitle: `TODO`,
            rejectExtensionTitle: `TODO`,
            cancelProposalTitle: `TODO`,
            rejectReason: `TODO`,
            reasonPlaceholder: `TODO`,
            notes: `TODO`,
            notesPlaceholder: `TODO`,
            notesRequired: `TODO`,
        },
        contactInformation: {
            feeDescription: `TODO`,
            clickHere: `TODO`,
            heading: `TODO`,
            description: `TODO`,
            firstName: `TODO`,
            lastName: `TODO`,
            title: `TODO`,
            phoneNumber: `TODO`,
            email: `TODO`,
            agreementLabel: `TODO`,
            clientAgreement: `TODO`,
            agreementLabel2: `TODO`,
            error: {
                phoneError: `TODO`,
                readClientAgreement: `TODO`,
            },
        },
        guestRoom: {
            heading: `TODO`,
            description: `TODO`,
            noGuestRoomRequest: `TODO`,
            commissionHeading: `TODO`,
            commissionCopy: `TODO`,
            roomsAvailable: `TODO`,
            addRooms: `TODO`,
            noRooms: `TODO`,
            guestNumber: `TODO`,
            roomImage: `TODO`,
            roomType: `TODO`,
            roomRate: `TODO`,
            commissionable: `TODO`,
            commissionRate: `TODO`,
            suggestedCommissionRate: `TODO`,
            commissionRateNote: `TODO`,
            resortFee: `TODO`,
            occupancyTax: `TODO`,
            copyRoomDetails: `TODO`,
            copyRoomDetailsToAll: `TODO`,
            error: {
                fillAll: `TODO`,
                answerRequest: `TODO`,
                notifyCommissionable: `TODO`,
            },
        },
        eventSpaces: {
            heading: `TODO`,
            eventSpacesDescription: `TODO`,
            noMeetingSpaceRequest: `TODO`,
            clientSpaceRequest: `TODO`,
            setupRequests: `TODO`,
            setupRequestsDescription: `TODO`,
            selectSpace: `TODO`,
            spaceIndex: (index: number) => `TODO`,
            spaceCount: (count: number) => `TODO`,
            guestsFormatter: (value: string) => `TODO`,
            seating: `TODO`,
            dailyRentalRate: `TODO`,
            dailyFB: `TODO`,
            perPersonRate: `TODO`,
            minGuestsNo: `TODO`,
            salesTax: `TODO`,
            serviceCharge: `TODO`,
            gratuity: `TODO`,
            dailyRate: `TODO`,
            feesAndTaxes: `TODO`,
            audioVisual: `TODO`,
            audioVisualProvided: `TODO`,
            diningStyle: `TODO`,
            foodBeverage: `TODO`,
            changeUnitHeading: `TODO`,
            changeUnitDescription: `TODO`,
            noSpaceAvailable: `TODO`,
            createNewSpace: `TODO`,
            spaceEditor: `TODO`,
            spaceEditorDescription: `TODO`,
            selectASpace: `TODO`,
            name: `TODO`,
            image: `TODO`,
            description: `TODO`,
            playbookDescription: `TODO`,
            descriptionPlaceholder: `TODO`,
            size: `TODO`,
            maxCapacity: `TODO`,
            noImageAvailable: `TODO`,
            capacity: `TODO`,
            editSpace: `TODO`,
            people: `TODO`,
            roomRatesCommissionable: `TODO`,
            fbCommissionable: `TODO`,
            copySpaceBelow: `TODO`,
            copySpaceToAll: `TODO`,
            error: {
                noVenueSpaceId: `TODO`,
                timeAndSetup: `TODO`,
                avIds: `TODO`,
                fb: `TODO`,
                minGuest: `TODO`,
                minFb: `TODO`,
                pricing: `TODO`,
                roomRateCommission: `TODO`,
                fbCommission: `TODO`,
            },
        },
        proposalNotes: {
            heading: `TODO`,
            subheading: `TODO`,
            additionalDetails: `TODO`,
            additionalDetailsDescription: `TODO`,
            addAttachment: `TODO`,
            spaceHeldUntil: `TODO`,
            spaceHeldUntilDescription: `TODO`,
            error: {
                spaceHeldEnd: `TODO`,
                spaceHeldEndInvalid: `TODO`,
                minDate: `TODO`,
            },
        },
    },
    venue: {
        tabs: {
            search: `TODO`,
            inquiries: `TODO`,
            venues: `TODO`,
            proposals: `TODO`,
        },
        createNewEvent: `TODO`,
        searchVenues: `TODO`,
        closeCopy: `TODO`,
        contactSupport: `TODO`,
        inquirySubmissionHelper: `TODO`,
        inquirySubmissionConfirm: `TODO`,
        addToInquiry: `TODO`,
        addedToInquiry: `TODO`,
        remove: `TODO`,
        notBookable: `TODO`,
        showingResultsNear: (query: string) => `TODO`,
        noResultsForFilters: `TODO`,
        noResultsForQuery: (query: string) => `TODO`,
        viewMap: `TODO`,
        lockedFiltersCopy: `TODO`,
        filters: `TODO`,
        filtersWithCount: (count: number) => `TODO`,
        filterBy: `TODO`,
        where: `TODO`,
        searchPlaceholder: `TODO`,
        when: `TODO`,
        attendeeCount: `TODO`,
        noLocationsForQuery: (query: string) => `TODO`,
        goToVenuePage: `TODO`,
        temporarilyClosed: `TODO`,
        preferred: `TODO`,
        propertyId: `TODO`,
        externalUrlHelper: `TODO`,
        featureHeadline: `TODO`,
        maximumCapacity: `TODO`,
        numberOfSpaces: `TODO`,
        carbonInfo: `TODO`,
        goToCarbonHelp: `TODO`,
        carbonHelpLink: `TODO`,
        carbonMeasurement: `TODO`,
        noData: `TODO`,
        featuredSpaces: `TODO`,
        maxGuestCapacity: (capacity: number) => `TODO`,
        addMeetingSpace: `TODO`,
        duplicate: `TODO`,
        addGuestRooms: `TODO`,
        addVirtualMeeting: `TODO`,
        virtualMeetingInfo: `TODO`,
        recommendation: `TODO`,
        essentials: `TODO`,
        total: `TODO`,
        details: `TODO`,
        messageVenue: `TODO`,
        yourVenueContact: `TODO`,
        meetingRooms: `TODO`,
        description: `TODO`,
        banquetRounds: `TODO`,
        boardRoomConference: `TODO`,
        classroom: `TODO`,
        cocktailRounds: `TODO`,
        crescentRounds: `TODO`,
        eshaped: `TODO`,
        hollowSquare: `TODO`,
        perimeterSeating: `TODO`,
        talkShow: `TODO`,
        theater: `TODO`,
        tshaped: `TODO`,
        ushaped: `TODO`,
        viewAllPhotos: (count: number) => `TODO`,
        imageNotAvailable: `TODO`,
        filterForm: {
            dinovaOnly: `TODO`,
            preferredVenuesOnly: `TODO`,
            venueType: `TODO`,
            notFiltered: `TODO`,
            distance: `TODO`,
            brands: `TODO`,
            attendeeCount: `TODO`,
            quickFilters: `TODO`,
        },
        inquiry: {
            newBudgetApproved: `TODO`,
            aboveMaximumBudget: `TODO`,
            addANote: `TODO`,
            addNotePlaceholder: `TODO`,
            approvalRequested: `TODO`,
            uhOh: `TODO`,
            bannerCopy: `TODO`,
            goToPlanner: `TODO`,
            noSlotsRemaining: `TODO`,
            noSlotsRemainingCopy: `TODO`,
            viewInquiries: `TODO`,
            buildYourInquiry: `TODO`,
            reviewInquiry: `TODO`,
            buildInquiryDescription: `TODO`,
            buildInquirySubDescription: `TODO`,
            buildHelper1: `TODO`,
            buildHelper2: `TODO`,
            submitInquiry: `TODO`,
            submitInquiries: `TODO`,
            submitForApproval: `TODO`,
            closeInquiry: `TODO`,
            viewInquiry: `TODO`,
            cancelInquiry: `TODO`,
            needsApproval: `TODO`,
            nudge: `TODO`,
            nudgeSuccess: `TODO`,
            nudgeDescription: `TODO`,
            lastNudged: (time: string) => `TODO`,
            // ex: Last nudged 8 hours ago.
            viewInquiryDetails: `TODO`,
            buildInquiry: `TODO`,
            viewProposal: `TODO`,
            viewProposalDetails: `TODO`,
            inquiryDetails: `TODO`,
            detailsSubHeading: `TODO`,
            datesFlexible: `TODO`,
            notes: `TODO`,
            notesForVenue: `TODO`,
            notesPlaceholder: `TODO`,
            notesPlaceholder2: `TODO`,
            notesPlaceholder3: `TODO`,
            meetingDate: `TODO`,
            warning: `TODO`,
            warningLine1: `TODO`,
            warningLine2: `TODO`,
            withinMaximumBudget: `TODO`,
            requestApproval: `TODO`,
            requestExtension: `TODO`,
            yourInquiries: `TODO`,
            editInquiry: `TODO`,
            exportProposals: `TODO`,
            compare: `TODO`,
            inquirySubheading: `TODO`,
            listingSubHeading: `TODO`,
            guestRoomRental: `TODO`,
            roomRentalTotal: `TODO`,
            guestRoomsCommissionable: `TODO`,
            amountDescription: `TODO`,
            guestRoomsPerNight: `TODO`,
            requestRejected: `TODO`,
            requestRejectedInfo: `TODO`,
            roomName: `TODO`,
            roomRate: `TODO`,
            occupancyTax: `TODO`,
            resortFee: `TODO`,
            meetingSpace: `TODO`,
            guestsNumber: (count: number) => `TODO`,
            minGuests: `TODO`,
            guests: `TODO`,
            perPerson: `TODO`,
            fbMin: `TODO`,
            roomRental: `TODO`,
            roomSizeWithUnit: (unit: string) => `TODO`,
            roomMaxCapacity: `TODO`,
            serviceCharge: `TODO`,
            salesTax: `TODO`,
            gratuity: `TODO`,
            meetingSpaceTotal: `TODO`,
            minDayNumber: (index: number) => `TODO`,
            eventSpaces: `TODO`,
            proposalTotal: `TODO`,
            proposalTotalHelper: `TODO`,
            submittedOn: (time: string) => `TODO`,
            rejectedOn: (time: string) => `TODO`,
            error: {
                noVenues: `TODO`,
                noBookings: `TODO`,
                dateRequired: `TODO`,
                startTimeRequired: `TODO`,
                startDateInvalid: `TODO`,
                endTimeRequired: `TODO`,
                setupRequired: `TODO`,
                attendeesRequired: `TODO`,
                dateInvalid: `TODO`,
                roomsRequired: `TODO`,
                roomsContinue: `TODO`,
                confirmRooms: `TODO`,
                linkRequired: `TODO`,
                protocolRequired: `TODO`,
                nudge: `TODO`,
                noNotes: `TODO`,
                serverError: `TODO`,
                approvalRequestSuccess: `TODO`,
                exceedMaxRoomNumber: (max: number) => `TODO`,
                exceedTotalMaxRoomNumber: (max: number) => `TODO`,
                inquiryValidation: `TODO`,
            },
        },
        planner: {
            heading: `TODO`,
            subHeading: `TODO`,
            schedule: `TODO`,
            scheduleDescription: `TODO`,
            scheduled: `TODO`,
            addDayBefore: `TODO`,
            addDay: `TODO`,
            date: `TODO`,
            startTime: `TODO`,
            endTime: `TODO`,
            meetingSpaceNamePlaceholder: `TODO`,
            roomSetup: `TODO`,
            selectSetup: `TODO`,
            noOfAttendees: `TODO`,
            audioVisual: `TODO`,
            FB: `TODO`,
            diningStyle: `TODO`,
            noOfRooms: `TODO`,
            notes: `TODO`,
            serviceType: `TODO`,
            selectService: `TODO`,
            link: `TODO`,
            additionalMeetingInfo: `TODO`,
            roomAccordionDescription: `TODO`,
            accommodations: `TODO`,
            guestRoomsLabel: `TODO`,
            guestRoomsDescription: `TODO`,
            virtualMeetings: `TODO`,
            type: `TODO`,
            typeDescription: `TODO`,
            invalidTimeRange: `TODO`,
            checkIn: `TODO`,
            checkOut: `TODO`,
            noDatesSelected: `TODO`,
            noDatesAdded: `TODO`,
            numberOfNightsNeed: `TODO`,
            savedSuccessfully: `TODO`,
        },
        proposal: {
            proposal: `TODO`,
            roomHeldUntil: `TODO`,
            extensionRejectedOn: `TODO`,
            extensionRequestSentOn: `TODO`,
            requestSent: `TODO`,
            proposalExpired: `TODO`,
            extensionRejected: `TODO`,
            proposalHasExpired: `TODO`,
            extensionRejectedAt: (venueName: string, timestamp: string, preposition?: string) => `TODO`,
            expiryExtensionRejected: (venueName: string, timestamp: string, preposition?: string) => `TODO`,
            extensionReqSentAt: (timestamp: string, preposition?: string) => `TODO`,
            contactVenueToUpdateExpiry: `TODO`,
            extensionRequested: `TODO`,
            resendRequestInquiry: `TODO`,
            resendRequest: `TODO`,
            cannotAcceptAfterExpiry: `TODO`,
            requestExtensionInquiry: `TODO`,
            acceptConfirmDescription: `TODO`,
            rejectConfirmDescription: `TODO`,
            cancelConfirmDescription: `TODO`,
            flexibleDates: `TODO`,
            nextStepHeadline: `TODO`,
            noFileSelected: `TODO`,
            dropHere: `TODO`,
            finalizeContract: `TODO`,
            markAsComplete: `TODO`,
            uploadSignedDocs: `TODO`,
            maxFileUploadSize: `TODO`,
            previouslyUploadedFiles: `TODO`,
            newlyUploadedFiles: `TODO`,
            uploadNotice: `TODO`,
            estimatesHelper: `TODO`,
            noContractDescription: `TODO`,
            emptyContractTitle: `TODO`,
            emptyContractDescription: `TODO`,
            contracting: `TODO`,
            defaultVenueDocCopy: `TODO`,
            defaultVenueDocAltCopy: `TODO`,
            estimateTotalRequired: `TODO`,
            contractRequired: `TODO`,
            finalSpendRequired: `TODO`,
            tabs: {
                contracting: `TODO`,
                signDocs: `TODO`,
                uploadDocs: `TODO`,
                paymentDetails: `TODO`,
                finalDetails: `TODO`,
            },
            sign: {
                headline: `TODO`,
                helper: `TODO`,
                acknowledgement: `TODO`,
                note1: `TODO`,
                note2: `TODO`,
                note3: `TODO`,
                note4: `TODO`,
                uploadHeadline: `TODO`,
                defaultUploadCopy: `TODO`,
            },
            payment: {
                headline: `TODO`,
                prompt: `TODO`,
                minSpend: `TODO`,
                maxSpend: `TODO`,
                requestSignedDoc: `TODO`,
                requestPayment: `TODO`,
                vPayment: `TODO`,
                requestExpress: `TODO`,
                vPayDescription: `TODO`,
                name: `TODO`,
                estimatedSpend: `TODO`,
                accountActivationThrough: `TODO`,
                termsConfirmation: `TODO`,
                cardHeadline: `TODO`,
                cardSubheadline: `TODO`,
                accountActiveSince: `TODO`,
                number: `TODO`,
                expDate: `TODO`,
                cvc: `TODO`,
                billingZip: `TODO`,
            },
            noInfo: `TODO`,
            finalizeHeadline: `TODO`,
            finalizeSubHeadline: `TODO`,
            noProposalsAcceptedYet: `TODO`,
            noVenueBookedYet: `TODO`,
            totalSpendHelper: `TODO`,
        },
        types: {
            corporateOffice: `TODO`,
            hotel: `TODO`,
            restaurant: `TODO`,
            activity: `TODO`,
            conferenceCenter: `TODO`,
            bookableOffice: `TODO`,
            bar: `TODO`,
            brewery: `TODO`,
            winery: `TODO`,
            museum: `TODO`,
            theater: `TODO`,
            uniqueVenue: `TODO`,
            eventVenue: `TODO`,
            professionalWorkspace: `TODO`,
            coworkingSpace: `TODO`,
            yourSearchLocation: `TODO`,
        },
    },
    message: {
        haveQuestion: `TODO`,
        sendMessageToVenue: (venueName: string) => `TODO`,
        inputPlaceholder: `TODO`,
        bookingCancelled: `TODO`,
        congrats: `TODO`,
        congratsDescription: `TODO`,
    },
    communication: {
        communication: `TODO`,
        invitesCommunication: `TODO`,
        description: `TODO`,
        playbook: `TODO`,
        meeting: `TODO`,
        deletePrompt: (type: string) => `TODO`,
        // type is either 'playbook' or 'meeting
        invite: `TODO`,
        noteSurvey: `TODO`,
        create: `TODO`,
        copiedURL: `TODO`,
        meetingCancelled: `TODO`,
        recipientCount: (count: number = 0) => `TODO`,
        sent: `TODO`,
        draft: `TODO`,
        event: `TODO`,
        launched: `TODO`,
        schedule: `TODO`,
        scheduled: `TODO`,
        scheduleFor: `TODO`,
        reschedule: `TODO`,
        rescheduleFor: `TODO`,
        deleteScheduleConfirm: `TODO`,
        newSubject: (name: string) => `TODO`,
        note: `TODO`,
        parcelCreated: (type: string) => `TODO`,
        parcelSaved: (type: string) => `TODO`,
        parcelSent: (type: string) => `TODO`,
        parcelPublished: (type: string) => `TODO`,
        virtualMeetingDetailsText: (serviceName: string) => `TODO`,
        messageHeadline: (firstName: string, lastName: string) => `TODO`,
        firstName: `TODO`,
        firstNamePlaceholder: `TODO`,
        lastName: `TODO`,
        lastNamePlaceholder: `TODO`,
        phone: `TODO`,
        email: `TODO`,
        rsvpComments: `TODO`,
        rsvpCommentsPlaceholder: `TODO`,
        attendanceType: `TODO`,
        inPerson: `TODO`,
        virtual: `TODO`,
        needOvernight: `TODO`,
        rsvpHeadline: (firstName: string, lastName: string, name: string) => `TODO`,
        clickVMLink: `TODO`,
        clickToRespond: `TODO`,
        registerToAttend: `TODO`,
        notAttending: `TODO`,
        yourDetails: `TODO`,
        additionalInformation: `TODO`,
        accommodationRequirements: `TODO`,
        accommodationDescription: `TODO`,
        roomTypeHelper: `TODO`,
        expired: `TODO`,
        contactEmailOnExpiration: (dateTime: string, email: string) => `TODO`,
        contactSenderOnExpiration: (dateTime: string) => `TODO`,
        expiration: `TODO`,
        expirationCopy: (dateTime: string) => `TODO`,
        dietaryDefaultPrompt: `TODO`,
        dietaryPlaceholder: `TODO`,
        shareVirtualmeetingDetails: `TODO`,
        notShareVirtualmeetingDetails: `TODO`,
        subHeaderInvite: `TODO`,
        subHeaderNote: `TODO`,
        inviteName: `TODO`,
        noteName: `TODO`,
        editInvite: `TODO`,
        inviteTitle: `TODO`,
        editNoteSurvey: `TODO`,
        createNoteSurvey: `TODO`,
        recipients: `TODO`,
        recipientsPrompt: `TODO`,
        attendees: `TODO`,
        subject: `TODO`,
        subjectPlaceholder: `TODO`,
        eventSubjectPlaceHolder: `TODO`,
        headline: `TODO`,
        headlinePlaceholder: `TODO`,
        message: `TODO`,
        messagePlaceholder: `TODO`,
        image: `TODO`,
        addImage: `TODO`,
        imageUploadDescription: `TODO`,
        imageUploadPrompt: `TODO`,
        hotelBannerUploadPrompt: `TODO`,
        hotelLogoUploadPrompt: `TODO`,
        eventDates: `TODO`,
        copyToInvite: `TODO`,
        copyBelowToRegistrationPage: `TODO`,
        venue: `TODO`,
        venueName: `TODO`,
        streetAddress: `TODO`,
        cityState: `TODO`,
        noVirtualMeetings: `TODO`,
        rsvpEditHeadline: `TODO`,
        rsvpEditDescription: `TODO`,
        draftCreated: `TODO`,
        logo: `TODO`,
        addLogo: `TODO`,
        logoUploadDescription: `TODO`,
        uploadAnother: `TODO`,
        error: {
            fetchPlaybook: (id: string | number) => `TODO`,
            fetchMeeting: (id: string | number) => `TODO`,
            fetchMeetingParcel: (parcelId: string, eventId: string) => `TODO`,
            createParcel: (type: string) => `TODO`,
            schedulePast: `TODO`,
        },
        publicEventRegistration: `TODO`,
        publicEventRegistrationDescription: `TODO`,
        privateInvite: `TODO`,
        privateInviteDescription: `TODO`,
        surveyDescription: `TODO`,
        noteDescription: `TODO`,
        surveyNoteTitle: `TODO`,
        surveyNoteDescription: `TODO`,
        eventRegistrationTitle: `TODO`,
        privateInviteTitle: `TODO`,
        hotel_conf: `TODO`,
        hotelConfLabel: `TODO`,
        hotelConfTitle: `TODO`,
        hotelConfDescription: `TODO`,
        registration_page: `TODO`,
        survey: `TODO`,
        rsvp: `TODO`,
        eventRegistrationHeadline: `TODO`,
        eventRegistrationSubheader: `TODO`,
        eventTitle: `TODO`,
        eventTitlePlaceholder: `TODO`,
        eventDescription: `TODO`,
        eventDescriptionPlaceholder: `TODO`,
        publicEventRegistrationTitle: `TODO`,
        sendConfirmPrompt: {
            [INVITE]: (attendees: string) => `TODO`,
            [HOTEL_CONF]: (attendees: string) => `TODO`,
            [NOTE]: (attendees: string) => `TODO`,
            [SURVEY]: (attendees: string) => `TODO`,
        },
        confirmation: `TODO`,
        successfullyRegisteredForEvent: `TODO`,
        formValidationMessage: `TODO`,
        registerForEvent: `TODO`,
        when: `TODO`,
        time: `TODO`,
        city: `TODO`,
        address: `TODO`,
        failedToLoadEventRegistrationInfo: `TODO`,
        category: {
            eventRegistration: `TODO`,
            invites: `TODO`,
            surveyNotes: `TODO`,
            hotelConf: `TODO`,
        },
        validationError: {
            preferredRoomType: `TODO`,
        },
        noInvitesCreatedYet: `TODO`,
        invitesDescription: `TODO`,
    },
    questions: {
        dietaryNeeds: `TODO`,
        checkInOutDates: `TODO`,
        logisticsTypes: `TODO`,
        attendanceType: `TODO`,
        linearScale: `TODO`,
        textBox: `TODO`,
        dateSelector: `TODO`,
        singleSelect: `TODO`,
        multiSelect: `TODO`,
        question: `TODO`,
        questions: `TODO`,
        questionPlaceholder: `TODO`,
        optionPlaceholder: `TODO`,
        addOption: `TODO`,
        needDietaryRestriction: `TODO`,
        howToAttend: `TODO`,
        addQuestion: `TODO`,
        addSurveyQuestions: `TODO`,
        addSurveyQuestionsDescription: `TODO`,
        surveyTitle: `TODO`,
        responseDescription: `TODO`,
        status: `TODO`,
        name: `TODO`,
        email: `TODO`,
    },
    virtual: {
        headline: `TODO`,
        selected: `TODO`,
        preferred: `TODO`,
        addToMeeting: `TODO`,
        shareDescription: `TODO`,
        addConfirmation: `TODO`,
        removeConfirmation: (name: string) => `TODO`,
        error: {
            linkRequired: `TODO`,
            linkProtocol: `TODO`,
        },
    },
    scheduledForm: {
        attachDocuments: `TODO`,
        addDocument: `TODO`,
        selectColor: `TODO`,
        agendaPlaceholder: `TODO`,
        duration: `TODO`,
        descriptionPlaceholder: `TODO`,
    },
    guests: {
        removeConfirmation: (email: string) => `TODO`,
        updateConfirmation: (email: string) => `TODO`,
        removeMultipleGuests: `TODO`,
        removeAttendingGuestPrompt: `TODO`,
        removeNotAttendingGuestPrompt: `TODO`,
        total: `TODO`,
        notSent: `TODO`,
        invited: `TODO`,
        notInvited: `TODO`,
        attending: `TODO`,
        declined: `TODO`,
        roomBlocks: `TODO`,
        clickable: `TODO`,
        addPeople: `TODO`,
        addPeopleHelper: `TODO`,
        addPeopleByEmail: `TODO`,
        addMultiplePeople: `TODO`,
        emailsPlaceholder: `TODO`,
        addByEmail: `TODO`,
        importList: `TODO`,
        subHeading: `TODO`,
        exportList: `TODO`,
        guest: `TODO`,
        overnightAccommodationRequired: `TODO`,
        noOvernightAccommodationRequired: `TODO`,
        addNoteAboutAttendee: `TODO`,
        attendeesAdded: (count: number) => `TODO`,
        uploadCSVOrXLS: `TODO`,
        fieldRequirements: `TODO`,
        selectFile: `TODO`,
        needHelp: `TODO`,
        uploadPrompt: `TODO`,
        matchColumns: `TODO`,
        matchColumnDescription: `TODO`,
        matchFieldRequirements: `TODO`,
        uploadFields: {
            email: `TODO`,
            required: `TODO`,
            firstName: `TODO`,
            lastName: `TODO`,
            phone: `TODO`,
            id: `TODO`,
            hotelConfirmationNumber: `TODO`,
        },
        personAdded: `TODO`,
        peopleAdded: (count: number) => `TODO`,
        duplicateEmailsHelper: (count: number) => `TODO`,
        singleInvalidEmail: (email: string) => `TODO`,
        multipleInvalidEmails: (emails: string) => `TODO`,
        addPersonMessage: `TODO`,
        addPeopleMessage: (count: number) => `TODO`,
        error: {
            noAttendeesFound: `TODO`,
            removeAttendees: (eventId: string) => `TODO`,
            removeAttendee: (id: string, eventId: string) => `TODO`,
            updateAttendee: (email: string) => `TODO`,
        },
    },
    privacyPolicy: {
        // the following is broken into pieces because "Terms and Conditions" and "Privacy Policy" need to be turned into links when rendered
        iAccept: (prefix: string) => `TODO`,
        termsAndConditions: `TODO`,
        andConfirm: `TODO`,
        privacyPolicy: `TODO`,
    },
    reporting: {
        header: (name: string) => `TODO`,
        headerNoName: `TODO`,
        selectDateRange: `TODO`,
        exportAllMeetings: `TODO`,
        exportAllBookings: `TODO`,
        exportAllInquiries: `TODO`,
        exportPreferredVenues: `TODO`,
        exportActiveUsers: `TODO`,
        meetings: `TODO`,
        // sections here are named to match the data sets in the reporting components for clarity
        preferredVenues: {
            title: `TODO`,
            tooltip: `TODO`,
            total: `TODO`,
            preferredVenue: `TODO`,
            nonPreferred: `TODO`,
        },
        attendeesStats: {
            title: `TODO`,
            tooltip: `TODO`,
            invited: `TODO`,
            attended: `TODO`,
        },
        userStatistics: {
            title: `TODO`,
            tooltip: `TODO`,
            totalUsers: `TODO`,
            active: `TODO`,
            accepted: `TODO`,
            booked: `TODO`,
        },
        meetingsStats: {
            title: `TODO`,
            tooltip: `TODO`,
            total: `TODO`,
            inquired: `TODO`,
            accepted: `TODO`,
            booked: `TODO`,
            cancelled: `TODO`,
        },
        memberStats: {
            title: `TODO`,
            totalMembers: `TODO`,
            inquiriesSent: `TODO`,
            proposalsAccepted: `TODO`,
            contractsUploaded: `TODO`,
        },
        totalSpend: {
            title: `TODO`,
            tooltip: `TODO`,
            totalSpend: `TODO`,
            preferredVenues: `TODO`,
            date: `TODO`,
        },
        activeVenues: {
            title: `TODO`,
            tooltip: `TODO`,
        },
    },
    // FORM FIELDS
    authFields: {
        email: `TODO`,
        emailPlaceholder: `TODO`,
        sendLink: `TODO`,
        verifyEmail: `TODO`,
        companyName: `TODO`,
        firstName: `TODO`,
        lastName: `TODO`,
        phone: `TODO`,
        phoneNumber: `TODO`,
        jobTitle: `TODO`,
        department: `TODO`,
        or: `TODO`,
        signInWithGoogle: `TODO`,
        signUpWithGoogle: `TODO`,
        placeholder: (field: string) => `TODO`,
        sales: `TODO`,
        marketing: `TODO`,
        engineering: `TODO`,
        businessDevelopment: `TODO`,
        hr: `TODO`,
        other: `TODO`,
        salesMarketing: `TODO`,
        cateringFoodBeverage: `TODO`,
        operations: `TODO`,
        guestServices: `TODO`,
    },
    //POPUPS
    subscription: {
        needAccess: `TODO`,
        sorryHeader: `TODO`,
        upgradeAccount: `TODO`,
        noAccess: `TODO`,
        upgradeToPro: `TODO`,
        upgradeToEnterprise: `TODO`,
        proPerk1: `TODO`,
        proPerk2: `TODO`,
        proPerk3: `TODO`,
        enterprisePerk1: `TODO`,
        enterprisePerk2: `TODO`,
        enterprisePerk3: `TODO`,
        notRightNow: `TODO`,
    },
    // GENERICS
    button: {
        accept: `TODO`,
        add: `TODO`,
        apply: `TODO`,
        back: `TODO`,
        cancel: `TODO`,
        cancelEvent: `TODO`,
        confirm: `TODO`,
        continue: `TODO`,
        copy: `TODO`,
        copyUrl: `TODO`,
        clear: `TODO`,
        create: `TODO`,
        createEvent: `TODO`,
        defaultLiteral: `TODO`,
        delete: `TODO`,
        dismiss: `TODO`,
        download: `TODO`,
        edit: `TODO`,
        export: `TODO`,
        generateWithAI: `TODO`,
        import: `TODO`,
        invite: `TODO`,
        launch: `TODO`,
        manage: `TODO`,
        next: `TODO`,
        no: `TODO`,
        notRightNow: `TODO`,
        preview: `TODO`,
        previous: `TODO`,
        proceed: `TODO`,
        readMore: `TODO`,
        reload: `TODO`,
        reject: `TODO`,
        remove: `TODO`,
        replace: `TODO`,
        request: `TODO`,
        reset: `TODO`,
        register: `TODO`,
        resubmit: `TODO`,
        save: `TODO`,
        select: `TODO`,
        selectFile: `TODO`,
        sendMessage: `TODO`,
        skip: `TODO`,
        messageVenue: `TODO`,
        saveChanges: `TODO`,
        send: `TODO`,
        sent: `TODO`,
        set: `TODO`,
        submit: `TODO`,
        update: `TODO`,
        upload: `TODO`,
        view: `TODO`,
        viewAll: `TODO`,
        viewCard: `TODO`,
        yes: `TODO`,
        aiAssist: `TODO`,
        uploadCsv: `TODO`,
        replaceCsv: `TODO`,
    },
    modal: {
        areYouSure: `TODO`,
        unsavedPrompt: `TODO`,
        timeZone: `TODO`,
        timeZoneDescription: `TODO`,
        selectTimezone: `TODO`,
        editDetails: `TODO`,
        responded: `TODO`,
        pending: `TODO`,
        responses: `TODO`,
    },
    error: {
        default: `TODO`,
        submission: `TODO`,
        contactSupport: `TODO`,
        contactOrRefreshPage: `TODO`,
        contactOrRevisit: `TODO`,
        somethingWentWrong: `TODO`,
        fillAll: `TODO`,
        inquirySubmission: `TODO`,
        inquiryExist: `TODO`,
        inquiryEditSubmitted: `TODO`,
        copyUnsubmittedInquiry: `TODO`,
        eventProvider: `TODO`,
        cannotSendMessage: `TODO`,
        submitData: `TODO`,
        tooLargeFile: (fileName: string) => `TODO`,
        refreshAndTry: `TODO`,
        loadQuestion: `TODO`,
        deleteQuestion: `TODO`,
        saveQuestion: `TODO`,
        fetchMeetingData: (eventId: string) => `TODO`,
        validPhone: `TODO`,
        tryAgain: `TODO`,
        desktopOnlyMessage: `TODO`,
        maxFilesExceeded: `TODO`,
    },
    common: {
        inputPlaceholder: `TODO`,
        dayIndex: (index: number) => `TODO`,
        guestNumberFormatter: (guests: number) => `TODO`,
        guestRoomNeeded: `TODO`,
        meetingSpaceIndex: (index: number) => `TODO`,
        copyMeetingSpace: (copyCount: number) => `TODO`,
        guestRoomsIndex: (index: number) => `TODO`,
        guestFormatter: (count: number) => `TODO`,
        imageAlt: (image: string) => `TODO`,
        brand: `TODO`,
        maxGuestFormatter: (count: number) => `TODO`,
        spacesFormatter: (count: number) => `TODO`,
        teamLogoAlt: `TODO`,
        attachments: `TODO`,
        unknown: `TODO`,
        start: `TODO`,
        end: `TODO`,
        //time-related prepositions
        at: `TODO`,
        // example: 'You sent a request at 3:00pm`
        on: `TODO`,
        // example: 'You sent a request on December 15'
        agenda: `TODO`,
        inquiry: `TODO`,
        booking: `TODO`,
        concatWords: (...parmas: string[]) => `TODO`,
        required: `TODO`,
        optionIndex: (index: number) => `TODO`,
        noNotes: `TODO`,
        accommodation: `TODO`,
        hotelConfirmationNumber: `TODO`,
        error: `TODO`,
        time: `TODO`,
        sort: `TODO`,
        period: `TODO`,
        file: `TODO`,
        emptyValue: `TODO`,
    },
    currency: {
        CADLabel: `TODO`,
        EURLabel: `TODO`,
        GBPLabel: `TODO`,
        MXNLabel: `TODO`,
        USDLabel: `TODO`,
        DKKLabel: `TODO`,
        ISKLabel: `TODO`,
        SEKLabel: `TODO`,
        NOKLabel: `TODO`,
    },
    datetime: {
        datesCalculated: (info: string) => `TODO`,
        calculationTimeInfo: `TODO`,
        startDate: `TODO`,
        date: `TODO`,
        endDate: `TODO`,
        startTime: `TODO`,
        endTime: `TODO`,
        checkInDate: `TODO`,
        checkOutDate: `TODO`,
    },
    proTip: {
        note: `TODO`,
        proTip: `TODO`,
        proTips: `TODO`,
    },
    notFound: {
        header: `TODO`,
        subHeader: `TODO`,
        redirectionInSeconds: (seconds: number) => `TODO`,
        backToHome: `TODO`,
    },
    // API - these labels are designed to work with the API type declarations in src/api/*Api.ts
    api: {
        eventOptions: {
            avOptions: {
                internet: `TODO`,
                audioconferencing: `TODO`,
                videoconferencing: `TODO`,
                projectorScreen: `TODO`,
                flipChart: `TODO`,
                flatScreen: `TODO`,
            },
            fbOptions: {
                breakfast: `TODO`,
                lunch: `TODO`,
                dinner: `TODO`,
                morningBreak: `TODO`,
                afternoonBreak: `TODO`,
                reception: `TODO`,
            },
            diningStyles: {
                buffet: `TODO`,
                plated: `TODO`,
            },
            setupOptions: {
                banquetRounds: `TODO`,
                conference: `TODO`,
                classroom: `TODO`,
                crescentRounds: `TODO`,
                hollowSquare: `TODO`,
                reception: `TODO`,
                theater: `TODO`,
                uShape: `TODO`,
                pods: `TODO`,
            },
            dietaryRestrictionOptions: {
                vegan: `TODO`,
                veganDescription: `TODO`,
                vegetarian: `TODO`,
                vegetarianDescription: `TODO`,
                kosher: `TODO`,
                kosherDescription: `TODO`,
                halal: `TODO`,
                halalDescription: `TODO`,
                pescatarian: `TODO`,
                pescatarianDescription: `TODO`,
                glutenFree: `TODO`,
                glutenFreeDescription: `TODO`,
            },
            foodAllergyOptions: {
                dairyAllergy: `TODO`,
                dairyAllergyDescription: `TODO`,
                eggAllergy: `TODO`,
                eggAllergyDescription: `TODO`,
                peanutAllergy: `TODO`,
                peanutAllergyDescription: `TODO`,
                treeNutAllergy: `TODO`,
                treeNutAllergyDescription: `TODO`,
                soyAllergy: `TODO`,
                soyAllergyDescription: `TODO`,
                wheatAllergy: `TODO`,
                wheatAllergyDescription: `TODO`,
                fishAllergy: `TODO`,
                fishAllergyDescription: `TODO`,
                shellfishAllergy: `TODO`,
                shellfishAllergyDescription: `TODO`,
            },
        },
    },
};
