import { Box, styled, Typography } from '@mui/material';

const HeaderContainer = styled(Box)(({ theme: { getColor, EColors, spacing, shape } }) => ({
    boxSizing: 'border-box',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: spacing(2.5),
    paddingRight: spacing(2.5),
    backgroundColor: getColor(EColors.drWhite),
    borderTopLeftRadius: shape.borderRadius,
    borderTopRightRadius: shape.borderRadius,
}));

export default function ListingGuestRooms() {
    return (
        <>
            <HeaderContainer>
                <Typography variant="body1" fontWeight={600}>
                Guest Rooms
                </Typography>
            </HeaderContainer>
        </>
    );
}
