import { styled } from '@mui/material';
import Box from '@mui/material/Box';

export const GridContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
    boxSizing: 'border-box',
}));

export const RowContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    padding: theme.spacing(2.5),
    boxSizing: 'border-box',
    gap: theme.spacing(2.5)
}));

export const BorderedContainer = styled(Box)(({ theme: { getColor, EColors, shape } }) => ({
    height: '100%',
    border: `1px solid ${getColor(EColors.bizlyOSBorder)}`,
    borderRadius: shape.borderRadius,
    boxSizing: 'border-box',
}));
