import Divider from '@mui/material/Divider';
import {
    defaultEditParcelFields,
    editParcelSchemaTimeOnly,
    editParcelSchemaWithTz,
    roomBlockFields,
    roomBlockSchema,
    rsvpDueFields,
    rsvpDueSchema,
} from 'components/Communication/editParcelSchema';
import EventDateDisplay from 'components/EventDateDisplay';
import Form from 'components/Form/Form';
import { RichTextEditor } from 'components/Form/fields';
import { SelectField } from 'components/FormFields';
import { Questions } from 'components/Questions';
import { Spacer } from 'components/Ui-V2/Spacer/Spacer';
import { Uploader } from 'components/Uploader';
import { Headline, PageHeadline } from 'components/ui/Headline';
import { parseISO } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import { useUser } from 'providers/user';
import React, { useMemo } from 'react';
import { i18n } from 'translation';
import { Button, Column, Copy, Prompted, PromptedTextField, Row, SpacedRow, Switch, TextField } from 'ui';
import { EditParcelState, TEventParcelContext } from '../EditParcel';
import { vmSharingOptions } from './PrivateInvite';
import { Centered, CopyDatesButton, FormLabel, InviteeRoomBlock, Subheader } from './styles';

const EventRegistrationPage = ({
    context,
    handleChange,
    state,
    handleUploadChange,
    handleCopyDates,
}: {
    context: TEventParcelContext;
    handleChange: (value: any, field: string) => void;
    state: EditParcelState;
    handleUploadChange: (response: any) => void;
    handleCopyDates: () => void;
}) => {
    const { user } = useUser();
    const { parcel } = state;
    const isPublished = useMemo(
        () => !Array.isArray(parcel?.traits) && parcel?.traits?.public?.registrationPage?.published,
        [parcel?.traits]
    );

    const editParcelFields = useMemo(
        () => defaultEditParcelFields({ parcelStartDate: parcel.startDate ? parseISO(parcel.startDate) : null }),
        [parcel.startDate]
    );

    const isDisabled = !context.editable || isPublished;

    return (
        <Centered>
            <PageHeadline>{parcel.name || i18n.communication.eventRegistrationHeadline}</PageHeadline>
            <Subheader>{i18n.communication.eventRegistrationSubheader}</Subheader>

            <Spacer />
            <Spacer />

            {parcel.name && parcel.name !== parcel.subject && isPublished && (
                <PromptedTextField
                    onChange={e => handleChange(e.target.value, 'name')}
                    disabled
                    prompt={i18n.communication.eventRegistrationHeadline}
                    value={parcel.name || ''}
                    variant="outlined"
                    optional
                />
            )}

            <Spacer />
            <PromptedTextField
                onChange={e => handleChange(e.target.value, 'subject')}
                disabled={isDisabled}
                placeholder={i18n.communication.eventTitlePlaceholder}
                prompt={i18n.communication.eventTitle}
                value={parcel.subject || ''}
                variant="outlined"
            />
            <Spacer />

            <Prompted prompt={i18n.communication.eventDescription}>
                <RichTextEditor
                    field="content"
                    disabled={isDisabled}
                    placeholder={i18n.communication.eventDescriptionPlaceholder}
                    value={parcel.content || ''}
                    onChange={e => handleChange(e.value, 'content')}
                />
            </Prompted>

            <Spacer />
            {(!parcel.sent || parcel.customImage) && (
                <>
                    <FormLabel style={{ marginBottom: '16px' }}>{i18n.communication.image}</FormLabel>
                    <Uploader
                        disabled={isDisabled}
                        description={i18n.communication.imageUploadDescription}
                        onChange={(newValue: any) => {
                            handleChange(newValue?.url, 'customImageUrl');
                            handleChange(newValue, 'customImage');
                        }}
                        prompt={i18n.communication.imageUploadPrompt}
                        resource={isEmpty(parcel.customImage) ? null : parcel.customImage}
                        formButton={
                            <Button
                                onClick={(e: React.MouseEvent<HTMLButtonElement>) => e.preventDefault()}
                                secondary
                                variant="outlined"
                            >
                                {i18n.communication.addImage}
                            </Button>
                        }
                    />
                    <Spacer />
                    <FormLabel style={{ marginBottom: '16px' }}>{i18n.communication.logo}</FormLabel>
                    <Uploader
                        disabled={isDisabled}
                        description={i18n.communication.logoUploadDescription}
                        onChange={(response: any) => handleUploadChange(response)}
                        prompt={i18n.communication.imageUploadPrompt}
                        resource={isEmpty(parcel.customLogo) ? null : parcel.customLogo}
                        formButton={
                            <Button
                                onClick={(e: React.MouseEvent<HTMLButtonElement>) => e.preventDefault()}
                                secondary
                                variant="outlined"
                            >
                                {i18n.communication.addLogo}
                            </Button>
                        }
                    />
                    <Spacer />
                </>
            )}

            {!context.asPlaybookParcel && context.meeting?.startsAt && (
                <>
                    <FormLabel $color="accentedHeadline">{i18n.communication.eventDates}</FormLabel>
                    <Row justifyContent="space-between" alignItems="center" wrap>
                        <EventDateDisplay />
                        <CopyDatesButton secondary variant="outlined" onClick={handleCopyDates} disabled={isDisabled}>
                            {i18n.communication.copyBelowToRegistrationPage}
                        </CopyDatesButton>
                    </Row>
                </>
            )}
            <Spacer />

            <Form
                fields={editParcelFields}
                schema={context.asPlaybookParcel ? editParcelSchemaTimeOnly : editParcelSchemaWithTz}
                value={{
                    ...parcel,
                    timeZone: parcel.sent ? parcel.timeZone : context.timeZone,
                }}
                onChange={(e: any) => handleChange(e.value[e.field], e.field)}
                disabled={isDisabled}
            />

            {!context.asPlaybookParcel && (
                <>
                    <Spacer />
                    <PromptedTextField
                        onChange={e => handleChange(e.target.value, 'locationName')}
                        disabled={isDisabled}
                        placeholder={i18n.communication.venueName}
                        optional
                        prompt={i18n.communication.venue}
                        value={parcel.locationName || ''}
                        variant="outlined"
                    />
                    <TextField
                        onChange={e => handleChange(e.target.value, 'locationAddress')}
                        disabled={isDisabled}
                        placeholder={i18n.communication.streetAddress}
                        value={parcel.locationAddress || ''}
                        variant="outlined"
                        fullWidth={true}
                    />
                    <TextField
                        onChange={e => handleChange(e.target.value, 'locationCityState')}
                        disabled={isDisabled}
                        placeholder={i18n.communication.cityState}
                        value={parcel.locationCityState || ''}
                        variant="outlined"
                        fullWidth={true}
                    />
                </>
            )}

            {!context.asPlaybookParcel && (
                <>
                    <Spacer />
                    <FormLabel $color="accentedHeadline">
                        {i18n.meetingDashboard.vendorsSection.virtualMeeting}
                    </FormLabel>
                    <Spacer xsmall />
                    {context.virtualMeeting ? (
                        <SelectField
                            field="vmSharingMethod"
                            value={vmSharingOptions.find(option => option.id === parcel.vmSharingMethod)}
                            readonly={false}
                            disabled={parcel.sent || !context.editable}
                            onChange={(e: any) => handleChange(e.value.target.value, 'vmSharingMethod')}
                            options={vmSharingOptions}
                        />
                    ) : (
                        <>
                            <Spacer xsmall />
                            <Copy>{i18n.communication.noVirtualMeetings}</Copy>
                        </>
                    )}
                </>
            )}

            <Spacer />

            <SpacedRow>
                <Column>
                    <Headline>{i18n.meetingDashboard.roomBlockSection.headline}</Headline>
                </Column>
                <Column style={{ flexDirection: 'row' }}>
                    <Copy style={{ marginRight: '10px' }} $color={isDisabled ? 'grey' : 'black'}>
                        {parcel.roomBlockEnabled
                            ? i18n.meetingDashboard.roomBlockSection.on
                            : i18n.meetingDashboard.roomBlockSection.off}
                    </Copy>
                    <Switch
                        disabled={isDisabled}
                        checked={parcel.roomBlockEnabled}
                        onChange={e => handleChange(e.target.checked, 'roomBlockEnabled')}
                    />
                </Column>
            </SpacedRow>

            <Copy>{i18n.meetingDashboard.roomBlockSection.description}</Copy>
            <Spacer />

            {Boolean(parcel.roomBlockEnabled) && (
                <>
                    <InviteeRoomBlock>
                        <Copy style={{ fontStyle: 'italic' }}>
                            {i18n.meetingDashboard.roomBlockSection.formSectionHelper}
                        </Copy>
                        <Spacer />
                        <Form
                            fields={roomBlockFields(false)}
                            schema={roomBlockSchema}
                            value={{
                                checkInDate: '',
                                checkOutDate: '',
                                preferredRoomType: null,
                                rsvpComments: 'Notes',
                            }}
                            onChange={() => {}}
                            disabled={true}
                        />
                    </InviteeRoomBlock>
                    <Spacer />
                    <Form
                        fields={rsvpDueFields}
                        schema={rsvpDueSchema}
                        value={{
                            rsvpDueDate: parcel.rsvpDueDate,
                            rsvpDueTime: parcel.rsvpDueTime,
                            postCutoffContact: user.email,
                        }}
                        onChange={(e: any) => handleChange(e.value[e.field], e.field)}
                        disabled={isDisabled || context.asPlaybookParcel}
                    />
                </>
            )}

            <Divider />

            <Spacer />

            {parcel.id && (
                <>
                    <Headline>{i18n.communication.rsvpEditHeadline}</Headline>
                    <Copy>{i18n.communication.rsvpEditDescription}</Copy>
                    <Spacer />
                    <Divider />
                    <Spacer />
                    <Questions
                        disabled={isDisabled}
                        parcelId={parcel.id}
                        hideAttendanceType={!false}
                        hasLinearScale={true}
                    />
                </>
            )}
        </Centered>
    );
};

export default EventRegistrationPage;
