import Divider from '@material-ui/core/Divider';
import {
    defaultEditParcelFields,
    editParcelSchemaTimeOnly,
    editParcelSchemaWithTz,
    roomBlockFields,
    roomBlockSchema,
    rsvpDueFields,
    rsvpDueSchema,
} from 'components/Communication/editParcelSchema';
import EventDateDisplay from 'components/EventDateDisplay';
import { RichTextEditor } from 'components/Form/fields';
import Form from 'components/Form/Form';
import { SelectField } from 'components/FormFields';
import { OptionList } from 'components/OptionList';
import { Questions } from 'components/Questions';
import { Headline, PageHeadline } from 'components/ui/Headline';
import { Uploader } from 'components/Uploader';
import { parseISO } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import { useUser } from 'providers/user';
import React, { useMemo } from 'react';
import { i18n } from 'translation';
import { Column, Copy, Line, Prompted, PromptedTextField, Row, SpacedRow, Spacer, Switch, TextField } from 'ui';
import { EditParcelState, TEventParcelContext } from '../EditParcel';
import { Centered, CopyDatesButton, FormLabel, InviteeRoomBlock, Subheader } from './styles';

export const vmSharingOptions = [
    { id: 'share', name: i18n.communication.shareVirtualmeetingDetails },
    { id: 'dont_share', name: i18n.communication.notShareVirtualmeetingDetails },
] as const;

const PrivateInvite = ({
    context,
    handleChange,
    state,
    setState,
    handleUploadChange,
    handleCopyDates,
}: {
    context: TEventParcelContext;
    handleChange: (value: any, field: string) => void;
    state: EditParcelState;
    setState: (state: EditParcelState) => void;
    handleUploadChange: (response: any) => void;
    handleCopyDates: () => void;
}) => {
    const { user } = useUser();
    const { attendees, parcel, recipients } = state;

    const editParcelFields = useMemo(
        () => defaultEditParcelFields({ parcelStartDate: parcel.startDate ? parseISO(parcel.startDate) : null }),
        [parcel.startDate]
    );

    const eventId = context.id;

    return (
        <Centered>
            <PageHeadline>{parcel.name || i18n.communication.inviteTitle}</PageHeadline>
            <Subheader>{i18n.communication.subHeaderInvite}</Subheader>

            <Spacer />
            <Spacer />

            {parcel.sent && parcel.name && parcel.name !== parcel.subject && (
                <>
                    <PromptedTextField
                        onChange={e => handleChange(e.target.value, 'name')}
                        disabled
                        prompt={i18n.communication.inviteName}
                        value={parcel.name || ''}
                        variant="outlined"
                        optional
                    />
                    <Spacer />
                </>
            )}

            {!context.asPlaybookParcel && (
                <>
                    <FormLabel style={{ marginBottom: '1rem' }}>{i18n.communication.recipients}</FormLabel>
                    <OptionList
                        eventId={eventId}
                        disableAll={parcel.sent || !context.editable}
                        prompt={i18n.communication.recipientsPrompt}
                        onChange={(selection: number[]) => {
                            setState({ ...state, recipients: selection }); // We track recipients seperately from the parcel, to maintain an up to date list.
                            handleChange(selection, 'recipients');
                        }}
                        optionFormatter={(attendee: BizlyAPI.Attendee) =>
                            attendee.firstName && attendee.lastName
                                ? `${attendee.firstName} ${attendee.lastName} - ${attendee.email}`
                                : attendee.email
                        }
                        options={attendees}
                        optionsLabel={i18n.communication.attendees}
                        selected={parcel.recipients || recipients}
                    />
                    <Spacer />
                </>
            )}
            <PromptedTextField
                onChange={e => handleChange(e.target.value, 'subject')}
                disabled={parcel.sent || !context.editable}
                placeholder={i18n.communication.subjectPlaceholder}
                prompt={i18n.communication.subject}
                value={parcel.subject || ''}
                variant="outlined"
            />
            <Spacer />

            <Prompted prompt={i18n.communication.message}>
                <RichTextEditor
                    field="content"
                    disabled={parcel.sent || !context.editable}
                    placeholder={i18n.communication.messagePlaceholder}
                    value={parcel.content || ''}
                    onChange={e => handleChange(e.value, 'content')}
                />
            </Prompted>

            <Spacer />
            {(!parcel.sent || parcel.customImage) && (
                <>
                    <FormLabel style={{ marginBottom: '1rem' }}>{i18n.communication.image}</FormLabel>
                    <Uploader
                        disabled={parcel.sent || !context.editable}
                        ctaLabel={i18n.communication.addImage}
                        description={i18n.communication.imageUploadDescription}
                        onChange={(newValue: any) => {
                            handleChange(newValue?.url, 'customImageUrl');
                            handleChange(newValue, 'customImage');
                        }}
                        prompt={i18n.communication.imageUploadPrompt}
                        resource={isEmpty(parcel.customImage) ? null : parcel.customImage}
                    />
                    <Spacer />
                    <FormLabel style={{ marginBottom: '1rem' }}>{i18n.communication.addLogo}</FormLabel>
                    <Uploader
                        disabled={parcel.sent || !context.editable}
                        ctaLabel={i18n.communication.addLogo}
                        description={i18n.communication.logoUploadDescription}
                        onChange={(response: any) => handleUploadChange(response)}
                        prompt={i18n.communication.imageUploadPrompt}
                        resource={isEmpty(parcel.customLogo) ? null : parcel.customLogo}
                    />
                    <Spacer />
                </>
            )}

            {!context.asPlaybookParcel && context.meeting?.startsAt && (
                <>
                    <FormLabel $color="accentedHeadline">{i18n.communication.eventDates}</FormLabel>
                    <Row justifyContent="space-between" alignItems="center" wrap>
                        <EventDateDisplay />
                        <CopyDatesButton onClick={() => handleCopyDates()} disabled={parcel.sent || !context.editable}>
                            {i18n.communication.copyToInvite}
                        </CopyDatesButton>
                    </Row>
                </>
            )}
            <Spacer />

            <Form
                fields={editParcelFields}
                schema={context.asPlaybookParcel ? editParcelSchemaTimeOnly : editParcelSchemaWithTz}
                value={{
                    ...parcel,
                    timeZone: parcel.sent ? parcel.timeZone : context.timeZone,
                }}
                onChange={(e: any) => handleChange(e.value[e.field], e.field)}
                disabled={parcel.sent || !context.editable}
            />
            {!context.asPlaybookParcel && (
                <>
                    <Spacer />
                    <PromptedTextField
                        onChange={e => handleChange(e.target.value, 'locationName')}
                        disabled={parcel.sent || !context.editable}
                        placeholder={i18n.communication.venueName}
                        optional
                        prompt={i18n.communication.venue}
                        value={parcel.locationName || ''}
                        variant="outlined"
                    />
                    <TextField
                        onChange={e => handleChange(e.target.value, 'locationAddress')}
                        disabled={parcel.sent || !context.editable}
                        placeholder={i18n.communication.streetAddress}
                        value={parcel.locationAddress || ''}
                        variant="outlined"
                        fullWidth={true}
                    />
                    <TextField
                        onChange={e => handleChange(e.target.value, 'locationCityState')}
                        disabled={parcel.sent || !context.editable}
                        placeholder={i18n.communication.cityState}
                        value={parcel.locationCityState || ''}
                        variant="outlined"
                        fullWidth={true}
                    />
                </>
            )}

            {!context.asPlaybookParcel && (
                <>
                    <Spacer />
                    <FormLabel $color="accentedHeadline">
                        {i18n.meetingDashboard.vendorsSection.virtualMeeting}
                    </FormLabel>
                    <Spacer xsmall />
                    {context.virtualMeeting ? (
                        <SelectField
                            field="vmSharingMethod"
                            value={vmSharingOptions.find(option => option.id === parcel.vmSharingMethod)}
                            readonly={false}
                            disabled={parcel.sent || !context.editable}
                            onChange={(e: any) => handleChange(e.value.target.value, 'vmSharingMethod')}
                            options={vmSharingOptions}
                        />
                    ) : (
                        <>
                            <Spacer xsmall />
                            <Copy>{i18n.communication.noVirtualMeetings}</Copy>
                        </>
                    )}
                </>
            )}

            <Spacer />
            <Line />
            <Spacer />
            <SpacedRow>
                <Column>
                    <Headline>{i18n.meetingDashboard.roomBlockSection.headline}</Headline>
                </Column>
                <Column style={{ flexDirection: 'row' }}>
                    <Copy style={{ marginRight: '10px' }}>
                        {parcel.roomBlockEnabled
                            ? i18n.meetingDashboard.roomBlockSection.on
                            : i18n.meetingDashboard.roomBlockSection.off}
                    </Copy>
                    <Switch
                        checked={parcel.roomBlockEnabled}
                        onChange={e => handleChange(e.target.checked, 'roomBlockEnabled')}
                        disabled={parcel.sent}
                    />
                </Column>
            </SpacedRow>

            <Copy>{i18n.meetingDashboard.roomBlockSection.description}</Copy>
            <Spacer />
            {Boolean(parcel.roomBlockEnabled) && (
                <>
                    <InviteeRoomBlock>
                        <Copy style={{ fontStyle: 'italic' }}>
                            {i18n.meetingDashboard.roomBlockSection.formSectionHelper}
                        </Copy>
                        <Spacer />
                        <Form
                            fields={roomBlockFields(false)}
                            schema={roomBlockSchema}
                            value={{
                                checkInDate: '',
                                checkOutDate: '',
                                preferredRoomType: null,
                                rsvpComments: 'Notes',
                            }}
                            onChange={() => {}}
                            disabled={true}
                        />
                    </InviteeRoomBlock>
                    <Spacer />
                    <Form
                        fields={rsvpDueFields}
                        schema={rsvpDueSchema}
                        value={{
                            rsvpDueDate: parcel.rsvpDueDate,
                            rsvpDueTime: parcel.rsvpDueTime,
                            postCutoffContact: user.email,
                        }}
                        onChange={(e: any) => handleChange(e.value[e.field], e.field)}
                        disabled={parcel.sent || !context.editable || context.asPlaybookParcel}
                    />
                </>
            )}
            <Line />
            <Spacer />

            {parcel.id && (
                <>
                    <Headline>{i18n.communication.rsvpEditHeadline}</Headline>
                    <Copy>{i18n.communication.rsvpEditDescription}</Copy>
                    <Spacer />
                    <Divider />
                    <Spacer />
                    <Questions
                        disabled={parcel.sent || !context.editable}
                        parcelId={parcel.id}
                        hideAttendanceType={!false}
                        hasLinearScale={true}
                    />
                </>
            )}
        </Centered>
    );
};

export default PrivateInvite;
