import { Route, Routes } from 'react-router-dom';
import { BorderedContainer } from '../GridContainer';
import ListingContactInfo from './ListingContactInfo';
import ListingEventSpaces from './ListingEventSpaces';
import ListingExperienceAndAmenities from './ListingExperienceAndAmenities';
import ListingFAQs from './ListingFAQs';
import ListingGeneralInfo from './ListingGeneralInfo';
import ListingGuestRooms from './ListingGuestRooms';
import ListingHighlights from './ListingHighlights';
import ListingOtherSpaces from './ListingOtherSpaces';
import ListingPhotos from './ListingPhotos';
import ListingPolicies from './ListingPolicies';
import {
    CONTACT_INFO_PATH,
    EVENT_SPACES_PATH,
    EXPERIENCE_AMENITIES_PATH,
    FAQS_PATH,
    GENERALINFO_PATH,
    GUEST_ROOMS_PATH,
    HIGHLIGHTS_PATH,
    OTHER_SPACES_PATH,
    PHOTOS_PATH,
    POLICIES_PATH,
} from './ListingsSubNavigation';

function ListingBody() {
    return (
        <BorderedContainer>
            <Routes>
                <Route path={GENERALINFO_PATH} element={<ListingGeneralInfo />} />
                <Route path={PHOTOS_PATH} element={<ListingPhotos />} />
                <Route path={CONTACT_INFO_PATH} element={<ListingContactInfo />} />
                <Route path={EXPERIENCE_AMENITIES_PATH} element={<ListingExperienceAndAmenities />} />
                <Route path={HIGHLIGHTS_PATH} element={<ListingHighlights />} />
                <Route path={GUEST_ROOMS_PATH} element={<ListingGuestRooms />} />
                <Route path={EVENT_SPACES_PATH} element={<ListingEventSpaces />} />
                <Route path={OTHER_SPACES_PATH} element={<ListingOtherSpaces />} />
                <Route path={FAQS_PATH} element={<ListingFAQs />} />
                <Route path={POLICIES_PATH} element={<ListingPolicies />} />
            </Routes>
        </BorderedContainer>
    );
}

export default ListingBody;
