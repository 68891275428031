import { Box, styled } from '@mui/material';
import { InsightsContainer } from 'components/BizlyOS/InsightsContainer';
import ListingBody from 'components/BizlyOS/Listings/ListingBody';
import ListingHeader from 'components/BizlyOS/Listings/ListingHeader';
import ListingsSubNavigation from 'components/BizlyOS/Listings/ListingsSubNavigation';

const Container = styled(Box)(({}) => ({
    height: '100%',
    boxSizing: 'border-box',
}));

const headerHeight = '60px';

const HeaderContainer = styled(Box)(({ theme: { getColor, EColors } }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 20px',
    borderBottom: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
    height: headerHeight,
}));

const BodyContainer = styled(Box)(({ theme }) => ({
    height: `calc(100% - ${headerHeight})`,
    boxSizing: 'border-box',
    display: 'grid',
    alignItems: 'flex-start',
    gridTemplateColumns: '220px minmax(480px,730px) 380px',
    gridTemplateRows: '1fr',
    gap: theme.spacing(2.5),
    padding: theme.spacing(2.5),
    overflowX: 'auto',
}));

export default function Listing() {
    return (
        <Container>
            <HeaderContainer>
                <ListingHeader state="create" />
            </HeaderContainer>
            <BodyContainer>
                <ListingsSubNavigation />
                <ListingBody />
                <InsightsContainer
                    title="First Impressions Matter"
                    descriptions={[
                        {
                            text: 'Your general information is the first thing planners read. Make it count! A well-crafted profile builds trust and encourages bookings.',
                        },
                        { text: 'Listings with detailed descriptions receive 5x more inquiries.', fontWeight: '500' },
                    ]}
                />
            </BodyContainer>
        </Container>
    );
}
